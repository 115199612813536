footer {
	overflow: hidden;
}
.footer {
	&__inner {
		@include breakpoint-lg {
			position: relative;
			@include padding(t, 8);
			@include padding(b, 7);

			&::before,
			&::after {
				content: "";
				width: 100vw;
				height: 0;
				border-top: 1px solid $grey-dark;
				opacity: 0.1;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
			}

			&::before {
				top: 0;
			}

			&::after {
				bottom: 0;
			}
		}
	}
}
.footer-section {
	position: relative;
	@include padding(y, 5);

	@include breakpoint-lg {
		@include padding(y, 0);
	}

	&::before {
		content: "";
		width: 100vw;
		height: 0;
		border-top: 1px solid $grey-dark;
		opacity: 0.1;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translateX(-50%);

		@include breakpoint-lg {
			display: none;
		}
	}

	&__heading {
		@include text-teal;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		label {
			content: "";
			width: 1rem;
			height: 1rem;

			@include bg-img("icons/plus-teal.svg");
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;

			@include breakpoint-lg {
				display: none;
			}
		}
	}

	&__list {
		@include margin(t, 5);
		display: none;

		@include breakpoint-lg {
			display: block;
		}

		li:not(:last-child) {
			@include margin(b, 5);
		}
	}

	input[type="checkbox"]:checked ~ &__list {
		display: block;
	}
	input[type="checkbox"]:checked ~ &__list--social-icons {
		display: flex;
	}

	&__list--social-icons {
		@include margin(t, 7);
		display: none;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;

		@include breakpoint-lg {
			display: flex;
		}

		li:not(:last-child) {
			@include margin(b, 0);
			@include margin(r, 4);
		}

		img {
			width: 26px;
			height: auto;
		}

		a::before {
			display: none;
		}
	}
}

.footer-awards {
	@include margin(t, 7);

	@include breakpoint-lg {
		@include margin(t, 2);
	}

	ul {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		@include breakpoint-lg {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}

		li {
			// IE doesn't support grid gap
			@include margin(r, 5);
			@include margin(b, 4);

			@include breakpoint-lg {
				@include margin(r, 6);
			}
		}
	}
}

.footer-link {
	position: relative;
	color: $text-black;
	&::before {
		content: "";
		position: absolute;
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 2px;
		background: $access-teal;
		transform: scaleX(0);
		transform-origin: left;
		transition: transform 350ms ease-in-out;
	}
	&:hover {
		&::before {
			transform: scaleX(1);
		}
	}
}

.footer-accredation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	color: $text-black;

	@include padding(x, 2);
	@include padding(y, 6);

	@include breakpoint-lg {
		font-size: 16px;
		@include padding(x, 8);
		flex-direction: row;
		justify-content: space-between;
	}

	p {
		@include margin(b, 4);
	}
}
