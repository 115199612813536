.paginator {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	max-width: 400px;
	margin-left: auto;
	margin-right: auto;

	&__arrow {
		width: 16px;
		height: 12px;

		@include bg-img("icons/arrow-black.svg");
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;

		flex-shrink: 0;

		transition: transform 250ms ease-in-out;

		&:hover:not(.paginator__arrow--disabled) {
			transform: scale(1.5);
		}
		&--previous {
			transform: rotate(180deg);

			&:hover:not(.paginator__arrow--disabled) {
				transform: rotate(180deg) scale(1.5);
			}
		}

		&--disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}

	&__pages {
		@include margin(x, 5);
		@include padding(y, 4);

		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;

		flex-grow: 1;
	}

	&__number {
		@include padding(x, 2);

		position: relative;

		display: flex;
		justify-content: center;
		align-items: center;

		font-size: 16px;
		text-align: center;
		color: $text-black;
		line-height: 1;

		&--selected {
			color: $white;
			font-weight: bold;

			&::before {
				position: absolute;
				content: "";
				left: 50%;
				top: 50%;

				transform: translate(-50%, -50%);

				width: 42px;
				height: 42px;

				background-color: $access-teal;
				border-radius: 50%;

				z-index: -1;
			}
		}

		&:not(.paginator__number--selected) {
			&::before {
				position: absolute;
				content: "";
				left: 50%;
				top: 50%;

				transform: translate(-50%, -50%) scale(0);

				width: 42px;
				height: 42px;

				background-color: transparentize($access-teal, 0.5);
				border-radius: 50%;

				z-index: -1;
				transition: transform 250ms ease-in-out;
			}

			&:hover {
				color: $white;
				transition: color 250ms ease-in-out;
				&::before {
					transform: translate(-50%, -50%) scale(1);
				}
			}
		}
	}
}
