.pricing {
	overflow: visible;
	@include padding(y, 5);

	@include breakpoint-lg {
		@include padding(y, 7);

		// Fix for safari - .pricing-card ignored z-index of header when rotating
		transform: translateZ(0);
	}
	// banner on pricing tier & table header
	.popular-banner {
		@include padding(x, 4);
		@include padding(y, 1);

		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		background: $access-teal;

		color: white;
		font-size: 16px;
		font-weight: 700;

		transform: translateY(-100%);
	}

	.pricing-card {
		position: relative;
		@include padding(y, 5);
		@include padding(x, 4);
		@include margin(b, 6);
		background: $bg-secondary;

		max-width: 480px;
		margin-left: auto;
		margin-right: auto;

		backface-visibility: hidden;

		// transform-origin: center left;

		z-index: 5;

		@include breakpoint-lg {
			@include margin(b, 0);
			height: 100%;
			opacity: 0;

			animation-name: cardAnim;
			animation-duration: 750ms;
			animation-delay: 500ms;
			animation-timing-function: ease-in-out;
			animation-fill-mode: forwards;
		}

		&--popular {
			.popular-banner {
				@include margin(x, -4);
				@include margin(t, -5);
				@include margin(b, -2);

				// display: flex;
			}
			// display: block;
			background: $access-teal-lighter-70;
			@include margin(t, 4);

			@include breakpoint-lg {
				@include margin(t, 0);

				animation-name: cardAnimPopular;
				animation-delay: 1.25s;
				animation-duration: 1s;
				transform-origin: center;

				transform: rotateY(90deg) scale(1);
			}

			.pricing-card__tier {
				background-color: white;
			}

			.tooltip {
				@include bg-img("icons/info-white.svg");
			}
		}

		&__inner {
			display: flex;
			flex-direction: column;
			// align-items: center;
			justify-content: center;

			text-align: center;

			height: 100%;
		}

		// Pricing tier title e.g. Elite / Enterprise etc.
		&__title {
			@include margin(b, 4);
			font-weight: 700;
			font-size: 30px;
			color: $text-black;
		}

		// pricing circle
		&__tier {
			@include margin(b, 5);
			margin-left: auto;
			margin-right: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			background-color: transparentize(#b4b8cd, 0.88);
			border-radius: 50%;

			width: 193px;
			height: 193px;
		}

		&__price {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;

			font-weight: 700;
			font-size: 29px;
			color: $black;
			line-height: 1;

			width: 100%;
			flex-shrink: 0;
		}

		&__price-amount {
			font-size: 93px;
			color: $text-black;
		}

		// Inside pricing circle
		&__tier-description {
			// width: 100%;
			flex-shrink: 1;
			font-size: 16px;
			color: $text-black;
			line-height: 1.5;
			text-align: center;
			max-width: 70%;
		}

		// Spacing for buttons,extra descriptions etc
		&__tier ~ * {
			@include margin(b, 5);
			margin-left: auto;
			margin-right: auto;
		}

		&__content {
			@include padding(t, 4);
			@include margin(t, 4);
			margin-left: 0;
			margin-right: 0;

			@include breakpoint-lg {
				@include padding(t, 6);
				border-top: 1px solid rgba(180, 184, 205, 0.2);
			}

			// border-top: ;

			.pricing-details {
				&__description {
					display: none;

					@include breakpoint-lg {
						@include margin(b, 5);
						display: block;

						text-align: center;
						font-size: 14px;
						line-height: 1.2;
						color: $text-black;
					}
				}
				&__heading {
					@include margin(b, 4);
					text-align: center;
					font-size: 18px;
					font-weight: bold;
					color: $black;
				}
				&__list {
					li {
						@include margin(b, 4);
						display: flex;
						align-items: center;
						justify-content: space-between;
						text-align: start;

						p {
							@include margin(x, 2);
							font-size: 14px;
							line-height: 1.2;
							flex-grow: 1;
						}

						&::before {
							content: "";
							@include bg-img("icons/tick.svg");

							width: 14px;
							height: 14px;
							flex-shrink: 0;

							background-position: center;
							background-size: contain;
							background-repeat: no-repeat;
						}
					}
				}
			}
		}

		&__footer {
			display: none;
			@include breakpoint-lg {
				position: relative;
				@include padding(t, 5);
				@include margin(b, 0);
				margin-top: auto;
				width: 100%;

				display: block;
				&::before {
					@include margin(x, -4);
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					content: "";
					// width: 100%;
					border-top: 1px solid rgba(180, 184, 205, 0.2);
				}
			}
		}
	}

	&__table-buttons {
		@include padding(t, 7);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.pricing-table-heading {
		display: none;
		@include margin(t, 9);
		@include margin(b, 8);
		color: $black;
		font-size: 36px;
		font-weight: 700;
		text-align: center;

		@include breakpoint-lg {
			// @include padding(b, 8);
			display: block;
		}
	}
	$column-bg: transparentize($bg-secondary, 0.7);
	.pricing-table {
		display: none;

		@include breakpoint-lg {
			@include padding(b, 8);
			display: flex;
			flex-direction: column;
		}

		&__header {
			// @include padding(x, 8);
			// @include padding(y, 3);

			position: sticky;
			top: $header-height;
			z-index: 5;

			background: $white;

			// Break header out to full width
			@each $size, $min-width in $breakpoints {
				$gutter: map-get($grid-gutter, $size);
				$max-width: map-get($max-widths, $size);
				@media (min-width: $min-width) {
					$margin: calc(-0.5 * (100vw - #{$max-width} - #{$gutter / 2}));
					// $padding: calc(0.5 * (100vw - #{$max-width} + #{2 * $gutter}));
					margin-left: $margin;
					margin-right: $margin;
					// padding-left: $padding;
					// padding-right: $padding;
				}
			}

			&.is-pinned {
				box-shadow: 0px 24px 40px rgba(180, 184, 205, 0.5);
				.table-header {
					border: none;
				}
			}

			.table-header {
				@include padding(b, 5);
				@include padding(t, 7);
				// @include padding(x, 4);
				// margin-left: -12px;
				// margin-right: -12px;
				font-weight: 700;
				color: $black;
				font-size: 18px;
				line-height: 1;
				text-align: center;

				border-bottom: 1px solid rgba(180, 184, 205, 0.5);

				&:not(:first-child):not(.table-header--most-popular) {
					background: $column-bg;
				}

				&--most-popular {
					background: $access-teal-lighter-80;
					.popular-banner {
						@include padding(y, 2);
						// Padding + text size + font vertical spacings
						margin-top: -34px;
					}
				}

				&:first-child {
					text-align: left;
				}

				&__inner {
					// @include padding(y, 4);
					@include margin(x, 8);
					display: grid;
					grid-template-columns: 2fr repeat(3, 1fr);
				}
			}
		}

		table + * {
			@include margin(t, 7);

			margin-left: auto;
			margin-right: auto;
		}

		table {
			@include margin(x, 8);
			display: flex;
			flex-direction: column;

			tbody {
				tr {
					display: grid;
					grid-template-columns: 2fr repeat(3, 1fr);
				}
				tr:first-child td {
					@include padding(t, 6);
				}
				td {
					@include padding(y, 4);
					color: $text-black;
					font-size: 16px;
					text-align: center;
					vertical-align: baseline;

					flex-grow: 1;

					&:not(:first-child) {
						background: $column-bg;
					}

					&.most-popular {
						background-color: $access-teal-lighter-80;
					}

					&:first-child {
						text-align: left;
						flex-grow: 2;
						p {
							float: left;
							line-height: 1.4;
						}
						.tooltip {
							float: right;
						}
					}
				}
			}
		}
	}
}

@keyframes cardAnim {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes cardAnimPopular {
	0% {
		opacity: 0;
		transform: translateZ(-1px) rotateY(90deg) scale(1);
	}

	50% {
		opacity: 1;
		transform: translateZ(-1px) rotateY(0) scale(1);
	}
	51% {
		opacity: 1;
		transform-origin: center;
		transform: translateZ(-1px) rotateY(0) scale(1);
	}

	65% {
		opacity: 1;
		transform-origin: center;
		transform: translateZ(-1px) rotateY(0) scale(1.05);
	}
	100% {
		opacity: 1;
		transform-origin: center;
		transform: translateZ(-1px) rotateY(0) scale(1);
	}
}
