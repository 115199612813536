$button-timing-fn: 350ms ease-in-out;
$button-border-radius: 100px;
@mixin button-before {
	@include margin(r, 4);

	content: "";

	position: absolute;
	left: 2px;
	top: 2px;
	content: "";
	width: 33px;
	height: 33px;

	border-radius: 18px;
	z-index: -1;

	transition: all #{$button-timing-fn};
	transform-origin: center;
}

@mixin button-after {
	content: "";
	position: absolute;
	left: 18px;
	top: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;

	width: 14px;
	height: 10px;

	transition: all #{$button-timing-fn};
	transform: translate(-50%, -50%);
}

button {
	outline: none;

	&:hover,
	&:focus,
	&:focus-within,
	&:focus-visible {
		outline: none;
	}
}

.btn,
.mktoForm .btn.btn {
	// position: relative;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	font-family: $font-family;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.02em;
	line-height: 1.5;
	text-align: center;
	white-space: nowrap;

	border-radius: $button-border-radius;

	cursor: pointer;

	z-index: 0;

	$parent: &;

	// Transparent + teal border & circle
	&--primary {
		@include padding(r, 6);
		@include padding(l, 7);
		@include padding(y, 2);
		color: $text-black;
		border: 1px solid $access-teal;
		transition: color #{$button-timing-fn};

		&::before {
			@include button-before;
			background: $access-teal;
		}

		&::after {
			@include button-after;
			@include bg-img("icons/arrow-white.svg");
		}

		&:hover,
		&.text-teal:hover {
			color: white;
			&::before {
				width: calc(100% - 4px);
				// height: 100%;
			}
			&::after {
				transform: translate(40%, -50%);
			}
		}
	}

	// Similar to primary but with background teal
	&--primary-alt {
		@include padding(r, 6);
		@include padding(l, 7);
		@include padding(y, 2);
		color: white;
		background: $access-teal;
		border: 1px solid $access-teal;
		transition: color #{$button-timing-fn};

		// box-shadow: 0 12px 20px $access-teal;

		&::before {
			@include button-before;
			background: $access-teal-lighter-20;
		}

		&::after {
			@include button-after;
			@include bg-img("icons/arrow-white.svg");
		}

		&:hover {
			color: white;
			&::before {
				width: calc(100% - 4px);
				// height: 100%;
			}
			&::after {
				transform: translate(40%, -50%);
			}
		}
	}
	// Transparent + red border & circle
	&--primary-attention {
		@include padding(r, 6);
		@include padding(l, 7);
		@include padding(y, 2);
		color: $text-black;
		border: 1px solid $access-red;
		transition: color #{$button-timing-fn};

		&::before {
			@include button-before;
			background: $access-red;
		}

		&::after {
			@include button-after;
			@include bg-img("icons/arrow-white.svg");
		}

		&:hover {
			color: white;
			&::before {
				width: calc(100% - 4px);
				// height: 100%;
			}
			&::after {
				transform: translate(40%, -50%);
			}
		}
	}

	// Solid block, no circle, box-shadow hover state
	&--secondary {
		@include padding(r, 4);
		@include padding(l, 6);
		@include padding(y, 2);

		color: white;

		border: 1px solid $access-teal;
		background: $access-teal;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;

			opacity: 0;
			box-shadow: 0 12px 20px $access-teal;
			border-radius: $button-border-radius;

			transition: opacity #{$button-timing-fn};
		}

		&::after {
			@include button-after;
			@include bg-img("icons/arrow-white.svg");
		}

		&:hover::before {
			opacity: 0.5;
		}

		&.btn-icon--none {
			@include padding(l, 4);
		}
	}
	// Similar to primary but in red
	&--attention {
		@include padding(r, 6);
		@include padding(l, 7);
		@include padding(y, 2);

		color: white;

		border: 1px solid $access-red;

		background: $access-red;

		box-shadow: 0px 12px 20px rgba(229, 23, 63, 0.15);

		&::before {
			@include button-before;
			background: $access-red-lighter-20;
		}

		&::after {
			@include button-after;
			@include bg-img("icons/arrow-white.svg");
		}
		&:hover {
			&::before {
				width: calc(100% - 4px);
				// height: 100%;
			}
			&::after {
				transform: translate(40%, -50%);
			}
		}
	}

	// Icon overrides
	&-icon {
		&--plus {
			&::after {
				@include bg-img("icons/plus-white.svg");
			}
		}
		&--minus {
			&::after {
				@include bg-img("icons/minus-white.svg");
			}
		}
		&--none {
			&::after {
				background-image: none;
			}
		}
	}

	// Size overrides
	&--dense {
		@include padding(r, 4);

		&#{$parent}--primary::before {
			@include margin(r, 2);
		}
	}

	&--subscribe {
		@include margin(r, 1);
		position: absolute;
		right: 0;
		top: 50%;

		transform: translateY(-50%);
		// box-shadow: none;

		@include padding(l, 4);
		@include padding(r, 6);

		&::after {
			left: auto;
			right: 0;
		}
		&::before {
			box-shadow: none;
		}
	}

	// Text colour overrides
	&.text-teal {
		color: $text-teal;
	}

	&.text-red {
		color: $text-red;
	}

	&.text-white {
		color: $text-white;
	}
}

// Video play button
.play-button {
	position: relative;
	color: white;
	font-family: $font-family;
	font-weight: bold;
	font-size: 12px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	// @include margin(t, 2);
	z-index: 5;

	$circle-diameter-mobile: 28px;
	$circle-diameter-desktop: 52px;

	&:focus,
	&:active {
		outline: none;
	}

	&::after {
		content: "";
		width: $circle-diameter-mobile;
		height: $circle-diameter-mobile;
		@include margin(b, 2);
		@include bg-img("icons/play-button.png");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		order: -1;

		@include breakpoint-lg {
			width: $circle-diameter-desktop;
			height: $circle-diameter-desktop;
		}
	}

	&::before {
		position: absolute;
		top: 0;
		left: 50%;
		content: "";
		width: $circle-diameter-mobile;
		height: $circle-diameter-mobile;
		background-color: $access-red-darker-10;

		z-index: -1;

		transform: scale(1) translateX(-50%) translateZ(1px);

		border-radius: 50%;

		animation: pulse 1.5s ease-out infinite forwards;

		//Not sure why this is left? Center is off
		transform-origin: left;

		@include breakpoint-lg {
			width: $circle-diameter-desktop;
			height: $circle-diameter-desktop;
		}
	}
}
// Play button animation
@keyframes pulse {
	from {
		transform: scale(1) translateX(-50%) translateZ(1px);
		opacity: 1;
	}
	to {
		transform: scale(1.5) translateX(-50%) translateZ(1px);
		opacity: 0;
	}
}

// Carousel next/previous buttons for testimonials etc.
.carousel-button {
	position: absolute;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	top: 50%;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;

	@include bg-img("icons/chevron.svg");
	background-repeat: no-repeat;
	background-size: 25% auto;
	background-position: center;
	background-color: white;

	z-index: 5;

	opacity: 1;
	visibility: visible;

	transition: opacity 350ms ease-in-out, visibility 350ms ease-in-out;

	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

	&.disabled {
		opacity: 0;
		visibility: hidden;
	}

	&--next {
		right: 2.5%;
		transform: translateY(-50%);
	}
	&--previous {
		left: 2.5%;
		transform: translateY(-50%) rotate(180deg);
	}
}
