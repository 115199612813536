.modal {
	position: fixed;

	display: flex;
	justify-content: center;
	align-items: center;

	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	z-index: 50;

	transition: opacity 250ms ease-in-out, visibility 250ms ease-in-out;

	&--hidden {
		opacity: 0;
		visibility: hidden;
		transition: opacity 10ms linear, visibility 10ms linear;
	}

	.overlay {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		background: $bg-overlay;
		z-index: -1;
	}

	.modal-content {
		@include margin(x, 4);
		@include margin(y, 6);

		@include padding(x, 4);
		@include padding(y, 5);

		position: relative;

		width: 100%;

		max-width: 800px;

		max-height: 95vh;
		overflow-y: auto;

		display: flex;
		justify-content: center;
		align-items: flex-start;

		@include breakpoint-lg {
			@include padding(x, 7);
			@include padding(y, 7);
			margin-left: auto;
			margin-right: auto;
		}

		&__inner {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.btn--close {
			@include padding(x, 2);
			@include padding(y, 2);
			@include margin(x, 2);
			@include margin(y, 3);
			@include bg-img("icons/close.svg");
			position: absolute;
			top: 0;
			right: 0;
			width: 20px;
			height: 20px;
		}

		.heading {
			font-size: 18px;
			font-family: $font-family;
			line-height: 2;
			text-align: center;

			@include breakpoint-lg {
				font-size: 31px;
			}
		}

		p {
			@include margin(b, 4);
			font-size: 14px;
			font-family: $font-family;
			line-height: 2;
			text-align: center;

			max-width: 70ch;
			margin-left: auto;
			margin-right: auto;

			@include breakpoint-lg {
				@include margin(b, 6);
				font-size: 16px;
			}
		}

		.modal-fields {
			.field-container {
				@include margin(x, 2);
				@include margin(b, 5);

				@include breakpoint-lg {
					@include margin(b, 6);
				}
			}
		}

		button[type="submit"] {
			&:not(.btn--subscribe) {
				@include margin(t, 3);
				width: 100%;
				@include breakpoint-lg {
					@include margin(t, 5);
				}
			}
		}

		p.text-copy.text-black--faded {
			@include margin(t, 4);
			@include margin(b, 0);
			font-size: 12px;
			max-width: none;
			line-height: 1.1;

			@include breakpoint-lg {
				@include margin(t, 6);
			}
		}
	}
}

// Showcase styles - ignore
.modal-showcase {
	@include padding(y, 8);

	& > .container > .row > .col {
		& > .heading {
			@include margin(b, 4);
		}
		& > p {
			@include margin(b, 8);
		}
	}
}
