.blog-inner {
	// Short banner customisation
	.short-banner__heading {
		@include padding(t, 11);

		&::before {
			display: none;

			@include breakpoint-lg {
				display: block;
			}
		}

		&::after {
			width: 50vw;
			height: 50vw;

			transform: translate(50%, 60%) rotate(65deg);
		}
	}
	.short-banner__inner {
		// Overlap onto heading area
		@include margin(t, -12);
		background-color: transparent;

		@include breakpoint-lg {
			@include margin(t, -14);
		}
	}

	.blog {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		max-width: 75ch;
		margin-right: auto;
		margin-left: auto;

		@include breakpoint-lg {
			max-width: 90ch;
		}

		&__meta {
			@include margin(b, 2);
			display: flex;
			width: 100%;
			align-items: center;

			@include breakpoint-lg {
				@include margin(b, 5);
			}

			* + * {
				@include margin(l, 3);
			}

			.date {
				font-weight: 600;
				font-size: 12px;
			}
		}

		&__heading {
			.heading {
				@include margin(b, 3);
				width: 100%;
				font-size: 26px;
				font-weight: 700;
				color: $text-black;

				@include breakpoint-lg {
					@include margin(b, 5);
					font-size: 45px;
				}
			}
		}

		&__author {
			@include margin(b, 3);
			font-weight: 600;
			width: 100%;

			@include breakpoint-lg {
				width: auto;
			}
		}

		&__socials {
			@include margin(b, 6);
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			width: 100%;

			@include breakpoint-lg {
				width: auto;
				margin-left: auto;
			}

			span {
				@include margin(r, 4);
			}

			.social {
				@include margin(r, 5);
				min-height: 18px;
				img {
					height: 100%;
					width: auto;
				}
			}
		}
		&__image {
			border-radius: $border-radius;
			overflow: hidden;

			width: 100%;

			img {
				width: 100%;
			}
		}

		&__article {
			@include padding(y, 6);

			@include breakpoint-lg {
				@include padding(t, 9);
			}

			img {
				@include margin(y, 6);
				width: 100%;
			}

			& + .blog__socials {
				margin-left: 0;
				@include margin(b, 9);

				@include breakpoint-lg {
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}

				span {
					@include breakpoint-lg {
						@include margin(b, 3);
						font-size: 14px;
						width: 100%;
					}
				}
			}
		}
	}
}
