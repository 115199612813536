.pricing-template {
	&.short-banner {
		.short-banner__heading {
			@include breakpoint-lg {
				@include bg-img("pricing_inner_heading.png");
				background-size: 90% auto;
				background-repeat: no-repeat;
				background-position: left top;

				@include breakpoint-xl {
					background-size: 85% auto;
					background-repeat: no-repeat;
					background-position: left top 5%;
				}
				@include breakpoint-2xl {
					background-size: 85% auto;
					background-repeat: no-repeat;
					background-position: left top 10%;
				}
			}
		}

		.sparkle--top-right {
			display: none;
			@include breakpoint-lg {
				display: block;
				animation-name: sparkle-inner--top-right;
				@include scaledToWidth(114, 112, 60);
				left: 98.5%;
				bottom: 98%;

				@include breakpoint-xl {
					left: 99%;
					@include scaledToWidth(114, 112, 70);
				}
			}
		}

		.pricing {
			@include breakpoint-lg {
				@include padding(x, 6);
			}
			// To fit width of "inner" part of page that overlaps with the header
			& > .container {
				max-width: none;
			}

			.pricing-table__header {
				@include margin(x, -6);
				@include padding(x, 6);
				$innerColumns: (
					$bp-xs: 24,
					$bp-sm: 24,
					$bp-md: 24,
					$bp-lg: 23,
					$bp-xl: 22,
					$bp-2xl: 22,
					$bp-3xl: 22,
				);
				@each $size, $min-width in $breakpoints {
					$gutter: map-get($grid-gutter, $size);
					$remainingScreenWidth: map-get($innerColumns, $min-width);

					@media (min-width: $min-width) {
						$margin: calc(
							-0.5 * (100vw * #{(($grid-columns - $remainingScreenWidth) / $grid-columns)} + #{$gutter} + (32px * 2) - 2px)
						);
						$padding: calc(
							0.5 *
								(100vw * #{(($grid-columns - $remainingScreenWidth) / $grid-columns)} + #{$gutter} + (32px * 2) - 2px)
						);
						margin-left: $margin;
						margin-right: $margin;
						padding-left: $padding;
						padding-right: $padding;
					}
				}

				.container {
					max-width: none;
				}
			}
		}
	}
}
