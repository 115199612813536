.product-banner {
	&__heading {
		@include padding(t, 6);
		@include padding(b, 11);

		@include breakpoint-lg {
			@include padding(t, 8);
			@include padding(b, 12);
			&::after {
				width: 45vw;
				height: 45vw;
				transform: translate(25%, 60%) rotate(65deg);
			}
		}

		.heading {
			@include margin(b, 5);
			font-size: 26px;
			@include breakpoint-lg {
				@include margin(b, 6);
				font-size: 45px;

				max-width: 760px;
				margin-right: auto;
				margin-left: auto;
			}
		}
	}

	.product-banner-tag {
		@include margin(b, 5);
		display: flex;
		align-items: center;
		justify-content: center;

		&__image {
			@include margin(r, 1);
			img {
				width: 18px;
				height: auto;
			}
		}

		&__label {
			font-size: 18px;
			font-weight: 400;

			@include breakpoint-lg {
				font-size: 22px;
			}
		}
	}

	.short-banner__inner {
		@include padding(t, 7);
		@include padding(b, 8);
		$y-shift: -1 * nth($spacings, 11);
		margin-top: $y-shift;
		background: transparent;

		// @include breakpoint-lg {
		// 	@include padding(t, 11);
		// 	$y-shift: -1 * nth($spacings, 13);
		// 	margin-top: $y-shift;
		// }
	}

	.product-banner-video-container {
		@include breakpoint-less-than-lg {
			@include margin(x, -5);
		}
	}

	.product-banner-video {
		position: relative;

		&__image {
			img {
				margin: auto;
			}
		}

		&__video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			video {
				display: none;
			}
		}

		&__play-button {
			@include margin(t, 4);
			@include margin(l, 1);
			position: absolute;

			width: 100%;
			height: 100%;

			top: 0;
			left: 0;

			display: flex;
			justify-content: center;
			align-items: center;

			@include breakpoint-lg {
				@include margin(t, 0);
				@include margin(l, 4);
			}
		}
	}
}
