.relative {
	position: relative;
}

.hidden {
	display: none !important;
}

.block {
	display: block !important;
}

.flex {
	display: flex !important;
}

.flex-col {
	flex-direction: column;
}

.flex-justify--start {
	justify-content: flex-start;
}

.flex-align--start {
	align-items: flex-start;
}

@each $size, $min-width in $breakpoints {
	@media (min-width: $min-width) {
		.hidden--#{$size} {
			display: none !important;
		}
		.block--#{$size} {
			display: block !important;
		}
		.flex--#{$size} {
			display: flex !important;
		}
		.inline-flex--#{$size} {
			display: inline-flex !important;
		}
	}
}

.text-start {
	text-align: start;
}

.text-center {
	text-align: center;
}

@each $size, $min-width in $breakpoints {
	@media (min-width: $min-width) {
		.text-start--#{$size} {
			text-align: start !important;
		}
		.text-center--#{$size} {
			text-align: center !important;
		}
	}
}
