.blog-listing {
	@include padding(y, 7);

	@include breakpoint-lg {
		overflow: visible;
		@include padding(y, 9);
	}

	.filter {
		display: flex;
		flex-direction: column;

		@include breakpoint-lg {
			position: sticky;
			top: $header-height + 12px;
		}

		&__label {
			@include margin(b, 3);
			font-weight: bold;
			font-size: 18px;
			color: $text-black;
			line-height: 1;

			@include breakpoint-lg {
				@include margin(b, 6);
			}
		}

		&__options {
			@include margin(t, 4);
			@include margin(x, 5);
			@include padding(x, 4);
			@include padding(y, 4);

			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;

			background: white;
			flex-grow: 1;
			box-shadow: 6px 6px 12px rgba(211, 215, 220, 0.8);
			z-index: 5;

			@include breakpoint-lg {
				padding: 0;
				margin: 0;
				display: flex;
				position: relative;
				box-shadow: none;
			}

			&-inner {
				display: flex;
				flex-direction: column;

				flex-grow: 1;
			}
		}

		&__selected {
			@include padding(x, 4);
			@include padding(y, 4);

			position: relative;

			flex-grow: 1;

			border-radius: 999px;
			border: 1px solid $form-border-colour;

			font-weight: bold;
			font-size: 16px;
			line-height: 1;
			color: transparentize(black, 0.5);

			&::after {
				@include margin(r, 4);
				position: absolute;
				content: "";

				width: 15px;
				height: 9px;

				right: 0;
				top: 50%;

				@include bg-img("icons/chevron_down-black.svg");
				background-size: 100% auto;
				background-repeat: no-repeat;
				background-position: center;

				transform: translateY(-50%);
			}

			@include breakpoint-lg {
				display: none;
			}
		}
		.filter__selected + input[type="checkbox"] {
			display: none;

			&:checked ~ .filter__options {
				display: block;
			}
		}
	}

	&__grid {
		@include padding(t, 6);
		@include padding(b, 6);
		display: grid;
		grid-template-columns: 1fr;

		@include breakpoint-lg {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.blog-list-item {
		@include margin(b, 7);
		position: relative;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
		background: white;
		z-index: 0;
		border-radius: $border-radius;

		@include breakpoint-lg {
			@include margin(r, 5);
			max-width: none;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: white;
			z-index: -1;
			border-radius: $border-radius;
		}
		&::after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			box-shadow: 6px 6px 12px rgba(211, 215, 220, 0.8);

			transition: opacity 250ms ease-in-out;
			z-index: -2;
			opacity: 0;

			border-radius: $border-radius;
		}

		&:hover {
			.blog-list-item__image {
				img {
					transform: translateZ(1px) scale(1.1);
				}
			}
			&::after {
				opacity: 1;
			}
		}

		&__image {
			@include margin(b, 4);
			width: 100%;
			border-radius: $border-radius;
			overflow: hidden;

			.img-wrapper {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				padding-top: 62.5%;
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				min-height: 100%;
				height: auto;

				transform: translateZ(1px) scale(1);
				transform-origin: center;
				transition: transform 750ms ease-in-out;
			}
		}

		&__meta {
			@include margin(b, 4);
			@include padding(x, 4);
			display: flex;
			justify-content: space-between;
			align-items: center;

			.text-copy {
				font-size: 12px;
			}
		}

		&__content {
			@include margin(b, 4);
			@include padding(x, 4);
		}

		&__heading {
			@include margin(b, 3);
			font-size: 16px;
			font-weight: 700;
			line-height: 1.4;
			color: $text-black;
		}

		&__description {
			font-size: 14px;
			line-height: 1.4;
		}
	}
}
