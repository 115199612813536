.news {
	@include padding(y, 8);
	&__inner {
	}

	&__heading {
		@include margin(b, 6);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		@include breakpoint-lg {
			@include margin(b, 8);
		}
	}

	&__slides {
		// @include fade-edges;
		position: relative;
		display: block;

		&::after,
		&::before {
			content: "";
			position: absolute;
			top: -20%;
			bottom: -20%;
			// left: 0;
			z-index: 1;
			width: 50%;

			pointer-events: none;

			opacity: 1;
			visibility: visible;
			transition: opacity 350ms ease-in-out, visibility 350ms ease-in-out;
			border-radius: 50%;
		}
		&::after {
			left: 0;
			display: none;
			@include breakpoint-lg {
				display: block;
			}
		}

		&::before {
			right: 0;
		}

		$primary-faded--full: transparentize($bg-primary, 1);
		$primary-faded--half: transparentize($bg-primary, 0.5);
		&::after {
			left: 0;
			background: radial-gradient(farthest-corner, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 75%);
			transform: translateX(-50%);
		}

		&::before {
			right: 0;
			background: radial-gradient(farthest-corner, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 75%);
			transform: translateX(50%);
		}
		&::after {
			display: block;
			// transform: translateX(-50%);
			@include breakpoint-lg {
				// transform: translateX(50%);
			}
		}

		&::before {
			// transform: translateX(50%);
		}

		// min-width: 0;
		// min-height: 0;
		&.hide-after::after {
			opacity: 0;
			visibility: hidden;
			// background: red;
		}
		&.hide-before::before {
			opacity: 0;
			visibility: hidden;
			// background: red;
		}

		.carousel-button {
			top: 45%;
		}
	}

	.news-carousel {
		// Fix for Slick JS Carousel
		overflow: hidden;
		min-width: 100%;
		width: 0;

		.news-slide {
			@include margin(b, 7);
			@include margin(r, 6);

			position: relative;
			max-width: 400px;
			// width: 350px;

			background: white;
			z-index: 0;
			border-radius: $border-radius;

			box-shadow: -11px 15.89px 17.8717px rgba(211, 215, 220, 0.4);

			@include breakpoint-lg {
				@include margin(x, 4);
			}
			@include breakpoint-xl {
				@include margin(x, 5);
			}
			@include breakpoint-2xl {
				@include margin(x, 6);
			}

			&:hover {
				.news-slide__image {
					img {
						transform: translateZ(1px) scale(1.1);
					}
				}
			}

			&__image {
				@include margin(b, 4);
				width: 100%;
				border-radius: $border-radius;
				overflow: hidden;

				.img-wrapper {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					padding-top: 70%;
				}

				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					min-height: 100%;
					height: auto;

					transform: translateZ(1px) scale(1);
					transform-origin: center;
					transition: transform 750ms ease-in-out;
				}
			}

			&__meta {
				@include margin(b, 4);
				@include padding(x, 4);
				display: flex;
				justify-content: space-between;
				align-items: center;

				.text-copy {
					font-size: 12px;
				}
			}

			&__content {
				@include margin(b, 5);
				@include padding(x, 4);
			}

			&__heading {
				@include margin(b, 3);
				font-size: 18px;
				font-weight: 700;
				line-height: 1.4;
				color: $text-black;

				@include breakpoint-lg {
					font-size: 22px;
				}
			}

			&__description {
				font-size: 12px;
				line-height: 1.4;

				@include breakpoint-lg {
					font-size: 14px;
				}
			}
		}
	}
}
