@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

.text-black {
	color: $text-black;

	&--faded {
		color: transparentize($black, 0.6);
	}
}

.text-white {
	color: $text-white;
}
.text-teal {
	color: $text-teal;
}
.text-red {
	color: $text-red;
}

.heading-1 {
	font-family: $font-family;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.14;

	@include breakpoint-lg {
		font-size: 52px;
		line-height: 1.21;
	}

	@include breakpoint-xl {
		font-size: 64px;
		line-height: 1.21;
	}

	@include breakpoint-2xl {
		font-size: 68px;
		line-height: 1.21;
	}
}

.heading-2 {
	font-family: $font-family;
	font-weight: bold;
	font-size: 26px;
	line-height: 1.4;

	@include breakpoint-lg {
		font-size: 34px;
	}
}

.subheading-1 {
	font-family: $font-family;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.4;

	@include breakpoint-lg {
		font-size: 18px;
	}
}

.text-copy {
	font-family: $font-family;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.6;
	letter-spacing: 0.02em;
}

.footer-heading {
	font-family: $font-family;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;

	@include breakpoint-lg {
		font-size: 22px;
		line-height: 30px;
	}
}

.nav-top-link {
	font-family: $font-family;
	font-weight: bold;
	font-size: 18px;
	line-height: 23px;
	color: $text-black;

	@include breakpoint-lg {
		font-size: 12px;
	}

	@include breakpoint-xl {
		font-size: 14px;
	}
}

.link-text {
	font-family: $font-family;
	&__title {
		font-size: 14px;
		line-height: 19px;
		color: $text-black;
		font-weight: bold;

		@include breakpoint-lg {
			font-size: 14px;
			line-height: 20px;
		}

		@include breakpoint-xl {
			font-size: 16px;
			line-height: 22px;
		}
	}

	&__description {
		font-size: 12px;
		line-height: 16px;
		color: fade-out($text-black, 0.3);
		font-weight: normal;
	}
}

.tag-text {
	font-family: $font-family;
	font-size: 14px;
	font-weight: 700;
	color: $access-teal;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h7,
.h8 {
	font-family: $font-family;
	font-weight: bold;
	line-height: 1.4;
	letter-spacing: -0.02em;
}

h1 {
	font-size: 68px;
}

h2 {
	font-size: 45px;
}

h3 {
	font-size: 42px;
}

h4 {
	font-size: 34px;
}

h5 {
	font-size: 30px;
}

h6 {
	font-size: 22px;
}

.h7 {
	font-size: 18px;
}

.h8 {
	font-size: 16px;
}

p {
	@extend.text-copy;
}

blockquote {
	@include padding(x, 4);
	@include padding(y, 6);

	@include margin(y, 7);

	position: relative;

	font-family: $font-family;
	font-size: 18px;
	line-height: 1.5;
	letter-spacing: 0.02em;
	color: $text-black;

	background-color: $access-teal-lighter-90;
	border-radius: $border-radius;

	@include breakpoint-lg {
		@include padding(x, 7);
		@include padding(y, 7);
	}

	&::before,
	&::after {
		content: "";
		position: absolute;

		width: 42px;
		height: 34px;

		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	&::before {
		@include margin(l, 4);
		@include bg-img("icons/quotemark_start-teal.svg");
		left: 0;
		top: 0;

		transform: translateY(-50%);
	}

	&::after {
		@include margin(r, 4);
		@include bg-img("icons/quotemark_end-teal.svg");
		right: 0;
		top: 100%;
		transform: translateY(-50%);
	}
}
