// Grey Palette
$grey-light: #f2f2f2;
$grey-border: #e2ecef;
$grey-soft: #999999;
$grey-med: #666666;
$grey-title: #4b4e52;
$grey-dark: #2e2e2e;

$white: #ffffff;
$black: #000000;

// Brand colours
$access-red: #e51734;
$access-teal: #54b9b3;
$access-purple: #4b112c;

$lighten-percentages: 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%;
$darken-percentages: 10%, 20%, 30%, 40%, 50%;

// Red Tints
$access-red-lighter-10: #e82e48;
$access-red-lighter-20: #ea455d;
$access-red-lighter-30: #ed5d71;
$access-red-lighter-40: #ef7485;
$access-red-lighter-50: #f28b9a;
$access-red-lighter-60: #f5a2ae;
$access-red-lighter-70: #f7b9c2;
$access-red-lighter-80: #fad1d6;
$access-red-lighter-90: #fce8eb;

// Red Shades
$access-red-darker-10: #ce152f;
$access-red-darker-20: #b7122a;
$access-red-darker-30: #a01024;
$access-red-darker-40: #890e1f;
$access-red-darker-50: #730c1a;

// Teal Tints
$access-teal-lighter-10: #65c0bb;
$access-teal-lighter-20: #76c7c2;
$access-teal-lighter-30: #87ceca;
$access-teal-lighter-40: #98d5d1;
$access-teal-lighter-50: #aadcd9;
$access-teal-lighter-60: #bbe3e1;
$access-teal-lighter-70: #cceae8;
$access-teal-lighter-80: #ddf1f0;
$access-teal-lighter-90: #eef8f7;

// Teal Shades
$access-teal-darker-10: #4ca7a1;
$access-teal-darker-20: #43948f;
$access-teal-darker-30: #3b827d;
$access-teal-darker-40: #326f6b;
$access-teal-darker-50: #2a5d5a;

// Purple Tints
$access-purple-lighter-10: #5d2941;
$access-purple-lighter-20: #6f4156;
$access-purple-lighter-30: #81586b;
$access-purple-lighter-40: #937080;
$access-purple-lighter-50: #a58896;
$access-purple-lighter-60: #b7a0ab;
$access-purple-lighter-70: #c9b8c0;
$access-purple-lighter-80: #dbcfd5;
$access-purple-lighter-90: #ede7ea;

// Purple Shades
$access-purple-darker-10: #440f28;
$access-purple-darker-20: #3c0e23;
$access-purple-darker-30: #350c1f;
$access-purple-darker-40: #2d0a1a;
$access-purple-darker-50: #260916;
