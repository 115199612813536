header {
	// overflow: hidden;
	position: sticky;
	top: 0;

	background: $bg-nav;

	border-bottom: 1px solid #e5e5ed;
	z-index: 10;
}

.header {
	@include padding(y, 5);
	// position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 10;
	@include breakpoint-lg {
		@include padding(y, 2);
	}

	img {
		max-width: 200px;
		@include breakpoint-lg {
			max-width: 160px;
		}

		@include breakpoint-xl {
			max-width: 100%;
		}
	}

	a.btn {
		@include breakpoint-lg {
			font-size: 12px;
			line-height: 1.75;
		}

		@include breakpoint-xl {
			font-size: 14px;
			line-height: 1.5;
		}
	}

	label.mobile-menu-open {
		@include bg-img("icons/burger_menu.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		width: 30px;
		height: 20px;

		@include breakpoint-lg {
			display: none;
		}
	}
	label.mobile-menu-close {
		@include bg-img("icons/close.svg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;

		width: 30px;
		height: 20px;

		@include breakpoint-lg {
			display: none;
		}
	}

	.nav-expanded {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100vh;

		display: none;

		flex-direction: column;

		// z-index: 10;

		@include breakpoint-lg {
			position: relative;
			display: flex;
			height: auto;
		}

		&::before {
			content: "";
			position: absolute;
			background-color: $bg-overlay;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		// Container for top level links
		&__inner {
			// @include padding(x, 4);
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			width: 90%;

			display: flex;
			flex-direction: column;

			background-color: $bg-nav;

			@include breakpoint-lg {
				position: relative;
				width: auto;

				flex-direction: row;
				align-items: center;
				justify-content: space-between;
			}
		}
	}

	#mobile-menu-open:checked ~ nav {
		display: flex;
	}

	// Logo & close button
	.mobile-menu-header {
		@include padding(x, 4);
		@include padding(y, 5);
		position: relative;
		display: flex;
		flex-direction: row;

		align-items: center;
		justify-content: space-between;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			// width: 100%;
			border-bottom: 1px solid #e5e5ed;
		}

		@include breakpoint-lg {
			display: none;
		}
	}
	// Container for primary top level links
	.nav-top-links {
		@include padding(y, 4);
		@include padding(x, 4);
		height: 100%;
		overflow-y: auto;
		// overflow-x: visible;
		flex-grow: 1;

		@include breakpoint-lg {
			@include margin(r, 4);
			background: transparent;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-end;
			overflow-y: visible;
		}
		@include breakpoint-xl {
			@include margin(r, 6);
		}

		&__inner {
			display: flex;
			flex-direction: column;
			align-items: center;

			@include breakpoint-lg {
				flex-direction: row;
				justify-content: flex-end;
			}
		}
	}

	// Primary nav item
	.nav-top-item {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		// @include padding(x, 0);

		a {
			@include padding(y, 4);
		}

		@include breakpoint-lg {
			@include padding(x, 3);
			justify-content: center;
			position: relative;
			width: auto;
			flex: 1 1 auto;
			max-width: 120px;
			flex-wrap: nowrap;
		}

		& > .chevron {
			@include padding(x, 2);
			@include padding(y, 4);
			@include margin(r, 2);

			@include breakpoint-lg {
				@include margin(l, 2);
			}
		}

		&::before {
			content: "";
			position: fixed;
			bottom: 0;
			left: 0;
			width: 100vw;
			height: 85.5vh;
			background: $bg-overlay;
			pointer-events: none;

			display: none;
		}
	}

	// Nested links container
	.nav-links {
		display: none;
		flex-basis: 100%;
		z-index: 20;

		// Position under chevron
		@include breakpoint-lg {
			@include margin(r, 3);
			position: absolute;
			right: 0;
			top: 100%;
		}

		&__inner {
			flex-direction: column;
			@include margin(x, -4);
			@include padding(y, 4);
			background: white;
			z-index: 0;
			flex-grow: 1;

			@include breakpoint-lg {
				// @include margin(t, 1);
				width: 480px;
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);

				box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
				border-radius: $border-radius;
			}

			@include breakpoint-xl {
				// @include margin(t, 1);
				width: 520px;
			}
		}

		&::before {
			content: "";
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: $bg-overlay;
			z-index: -1;

			display: none;

			@include breakpoint-lg {
				top: $header-height;
				width: 100%;
				pointer-events: none;
			}
		}
	}

	@include breakpoint-less-than-lg {
		.nav-top-item input:checked ~ .nav-links {
			display: flex;
		}
	}

	.nav-top-item:hover .nav-links {
		@include breakpoint-lg {
			display: flex;
		}
	}

	.nav-top-item:hover > .chevron {
		display: flex;
	}

	.nav-top-item > .chevron {
		@include bg-img("icons/chevron_down-teal.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 16px;
		height: 10px;
	}

	@include breakpoint-lg {
		.nav-top-item:hover .nav-links::before {
			display: block;
		}
	}

	.nav-inner-link {
		display: flex;
		flex-direction: row;

		align-items: center;
		// background: white;

		@include padding(x, 4);
		@include padding(y, 4);

		cursor: pointer;
		z-index: 10;

		border-radius: $border-radius;

		@include breakpoint-lg {
			@include margin(x, 4);
			@include margin(y, 1);

			&::after {
				content: "";
				position: absolute;
				width: 20px;
				height: 20px;
				background: white;
				top: 0;
				left: 50%;
				transform: translate(-50%, -30%) rotate(-45deg);
			}
		}

		&__image {
			@include margin(r, 2);
			position: relative;
			width: 47px;
			height: 47px;

			background: $bg-nav;
			border-radius: $border-radius;

			overflow: hidden;

			// Fix for Safari border-radius bug
			backface-visibility: hidden;
			transform: translate3d(0, 0, 0);

			@include breakpoint-lg {
				width: 52px;
				height: 52px;
			}

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				width: 50%;
				height: 50%;
				border-radius: 100%;
				background-color: $access-teal;

				transition: transform 500ms ease-in-out;

				transform: translate(-50%, -50%);
			}

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 30%;
				height: 30%;

				transform: translate(-50%, -50%);

				transition: transform 500ms ease-in-out;
			}
		}

		&:hover {
			background: $bg-nav;
			.nav-inner-link__image::before {
				transform: translate(-50%, -50%) scale(3);
			}

			.nav-inner-link__image img {
				transform: translate(-50%, -50%) scale(1.25);
			}
		}

		.link-text {
			display: flex;
			flex-direction: column;
			flex: 1 1 auto;
			max-width: 190px;

			&__title {
				@include margin(b, 2);

				@include breakpoint-lg {
					@include margin(b, 0);
				}
			}
			&__description {
			}
		}

		.chevron {
			@include margin(r, 2);
			margin-left: auto;

			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;

			width: 16px;
			height: 12px;
			flex-shrink: 0;

			&--right {
				@include bg-img("icons/chevron-thin.svg");
				opacity: 0.7;

				@include breakpoint-lg {
					display: none;
				}
			}
		}
	}

	.header-buttons {
		@include padding(x, 4);
		@include padding(y, 4);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background: $bg-nav;
		margin-top: auto;

		border-top: 1px solid #e5e5ed;
		@include breakpoint-lg {
			@include padding(y, 0);
			@include padding(x, 0);
			margin-top: 0;
			border-top: none;

			& > *:not(:last-child) {
				@include margin(r, 2);
			}
		}
	}
}
