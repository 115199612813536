.logo-lister {
	border-bottom: 1px solid $bg-secondary;
	&__inner {
		@include padding(y, 5);

		@include breakpoint-lg {
			align-items: center;
		}
	}
	&__heading {
		@include margin(b, 4);
		font-family: $font-family;
		color: $text-black;
		font-size: 16px;
		font-weight: 700;

		text-align: center;

		@include breakpoint-sm {
			font-size: 20px;
		}
		@include breakpoint-md {
			font-size: 24px;
		}

		@include breakpoint-lg {
			text-align: start;
			font-size: 28px;
		}
		@include breakpoint-xl {
			text-align: start;
			font-size: 34px;
		}
	}

	&__logos {
		// display: grid;
		// grid-template-columns: 1fr 1fr;
		// gap: 1rem;

		// @include breakpoint-lg {
		// 	grid-template-columns: repeat(4, 1fr);
		// }

		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		@include breakpoint-lg {
			justify-content: flex-start;
		}
	}

	&__logo {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-basis: 50%;

		@include breakpoint-lg {
			flex-basis: 25%;
		}

		& > img {
			@include padding(x, 4);
			@include margin(y, 4);
		}
	}
}
