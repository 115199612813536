.full-width {
	@include padding(y, 8);
	.heading {
		@include margin(b, 4);

		font-size: 30px;

		@include breakpoint-lg {
			font-size: 42px;
		}
	}

	&__image {
		@include margin(t, 6);

		@include breakpoint-lg {
			@include margin(t, 9);
		}
		width: 100%;
		img {
			width: 100%;
		}
	}

	p, .button-wrapper, .heading {
		max-width: 640px;
		margin-right: auto;
		margin-left: auto;
	}
}
