$testimonial-transition-fn: 350ms ease-in-out;
.testimonials {
	@include padding(t, 8);
	@include padding(b, 9);
	display: flex;
	flex-direction: column;

	.heading {
		@include margin(b, 7);
	}

	&__slides {
		@include margin(l, 4);
		@include breakpoint-lg {
			@include margin(l, 0);
		}

		&-container {
			position: relative;

			@include fade-edges;
		}
		// To show box-shadow
		.slick-list {
			overflow: visible;
		}
	}
}

.testimonials-slide {
	@include padding(x, 5);
	@include padding(t, 5);

	@include margin(r, 5);

	// max-width: 400px;
	border-radius: $border-radius;
	// Fix for Safari border-radius bug
	overflow: hidden;
	backface-visibility: hidden;
	transform: translate3d(0, 0, 0);

	transition: background-color #{$testimonial-transition-fn};

	&__heading {
		font-family: $font-family;
		font-size: 18px;
		font-weight: 700;
		@include margin(b, 4);
	}

	&__copy {
		@include margin(b, 5);
		max-width: 60ch;
	}

	&__author {
		@include margin(b, 6);
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		img {
			@include margin(r, 2);
			border-radius: 50%;
			width: 36px;
			height: auto;
		}
	}
	// Name & company container
	&__name-company {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex-grow: 1;

		// Quote mark
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);

			width: 32px;
			height: 32px;

			@include bg-img("quote_mark.svg");
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			opacity: 0.5;
		}
	}

	&__name {
		font-weight: 700;
		font-size: 14px;
		line-height: 21px;
	}

	&__company {
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
	}

	// Testimonial cta block
	&__link {
		@include padding(y, 5);

		// Match above padding
		@include padding(x, 5);

		// Negative of slide horizontal padding
		@include margin(x, -5);
		display: flex;
		justify-content: space-between;
		align-items: center;

		font-weight: 700;
		font-size: 16px;

		background-color: transparent;

		border-top: 1px solid rgba(255, 255, 255, 0.2);

		transition: background-color #{$testimonial-transition-fn}, color #{$testimonial-transition-fn};
	}
	// Arrow button
	&__link-cta {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: transparent;
		border: 1px solid rgba(255, 255, 255, 0.4);

		transition: background-color #{$testimonial-transition-fn};

		width: 34px;
		height: 34px;

		svg {
			width: 13px;

			path {
				stroke: rgba(255, 255, 255, 0.4);
				transition: stroke #{$testimonial-transition-fn};
			}
		}
	}
	/*
	 	Slide colours depending on block bg
	 */

	// White
	.block--primary & {
		color: $text-black;

		// background-color: rgba(255, 255, 255, 1);

		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

		&:hover {
			background: rgba(255, 255, 255, 0.2);

			.testimonials-slide__link {
				color: white;
				background-color: $access-teal;
			}
			.testimonials-slide__link-cta {
				background-color: white;
				border-color: transparent;
				svg path {
					stroke: $access-teal;
				}
			}
		}

		&__link {
			color: transparentize($grey-dark, 0.3);
			background-color: $bg-secondary;
		}
		&__link-cta {
			border-color: transparentize($grey-dark, 0.3);

			svg {
				path {
					stroke: transparentize($grey-dark, 0.3);
				}
			}
		}
	}
	// Grey
	.block--secondary & {
		color: $text-black;

		background-color: rgba(255, 255, 255, 1);

		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);

		&:hover {
			background: rgba(255, 255, 255, 0.2);

			.testimonials-slide__link {
				color: white;
				background-color: $access-teal;
			}
			.testimonials-slide__link-cta {
				background-color: white;
				border-color: transparent;
				svg path {
					stroke: $access-teal;
				}
			}
		}

		&__link {
			color: transparentize($grey-dark, 0.3);
			background-color: $bg-secondary;
		}
		&__link-cta {
			border-color: transparentize($grey-dark, 0.3);

			svg {
				path {
					stroke: transparentize($grey-dark, 0.3);
				}
			}
		}
	}

	// Purple
	.block--tertiary & {
		color: white;

		background-color: rgba(255, 255, 255, 0.1);

		box-shadow: 0px 6px 12px rgba(68, 72, 77, 0.1);

		&:hover {
			background: rgba(255, 255, 255, 0.2);

			.testimonials-slide__link {
				color: rgba(255, 255, 255, 1);
				background-color: $access-teal;
			}
			.testimonials-slide__link-cta {
				background-color: white;
				svg path {
					stroke: $access-teal;
				}
			}
		}

		&__link {
			color: rgba(255, 255, 255, 0.4);
		}
	}
}
