/*******
	Breakpoints / Layout / Grid
********/

$bp-xs: 320px;
$bp-sm: 640px;
$bp-md: 768px;
$bp-lg: 1024px;
$bp-xl: 1280px;
$bp-2xl: 1440px;
$bp-3xl: 1536px;

$breakpoints: (
	"xs": $bp-xs,
	"sm": $bp-sm,
	"md": $bp-md,
	"lg": $bp-lg,
	"xl": $bp-xl,
	"2xl": $bp-2xl,
	"3xl": $bp-3xl,
);

// Multiples of 16 from 0.25x to 48x
$spacings: (4px, 8px, 12px, 16px, 24px, 32px, 48px, 64px, 96px, 128px, 192px, 256px, 384px, 512px, 640px, 768px);

$grid-columns: 24;

$grid-gutter: (
	"xs": nth($spacings, 6),
	"sm": nth($spacings, 6),
	"md": nth($spacings, 6),
	"lg": nth($spacings, 7),
	"xl": nth($spacings, 8),
	"2xl": 80px,
	"3xl": nth($spacings, 9),
);

$max-widths: (
	"xs": calc(100% - #{map-get($grid-gutter, "xs")}),
	"sm": calc(#{map-get($breakpoints, "sm")} - #{2 * map-get($grid-gutter, "sm")}),
	"md": calc(#{map-get($breakpoints, "md")} - #{2 * map-get($grid-gutter, "md")}),
	"lg": calc(#{map-get($breakpoints, "lg")} - #{2 * map-get($grid-gutter, "lg")}),
	"xl": calc(#{map-get($breakpoints, "xl")} - #{2 * map-get($grid-gutter, "xl")}),
	"2xl": calc(#{map-get($breakpoints, "2xl")} - #{2 * map-get($grid-gutter, "2xl")}),
	"3xl": calc(#{map-get($breakpoints, "3xl")} - #{2 * map-get($grid-gutter, "3xl")}),
);

/*******
	Typography
********/
$font-family: "Open Sans", sans-serif;
$text-black: $grey-dark;
$text-white: #fff;
$text-teal: $access-teal;
$text-red: $access-red;

/*******
	Backgrounds
********/
$bg-primary: $white;
$bg-secondary: #f6f6f9;
$bg-tertiary: $access-purple-darker-30;
$bg-nav: #f6f6f9;
$bg-overlay: rgba(0, 0, 0, 0.6);

/*********
Elements
********/
$border-radius: 6px;
$form-border-colour: #c8c8d8;
