:root {
	--phone-width: 306;
	--phone-height: 656;
}

@function phone-w-relative($size) {
	@return calc(100% * #{$size} / var(--phone-width));
}
@function phone-h-relative($size) {
	@return calc(100% * #{$size} / var(--phone-height));
}

.banner {
	@include padding(t, 6);
	@include padding(b, 9);

	position: relative;
	overflow: hidden;

	@include breakpoint-lg {
		@include padding(t, 8);
		@include padding(b, 10);
	}

	// triangle bg
	&::before {
		content: "";
		position: absolute;
		left: 0%;
		top: 100%;

		@include margin(b, -8);

		width: 20vw;
		height: 20vw;

		background-color: $access-teal;
		opacity: 0.06;

		transform: translate(-50%, -50%) rotate(45deg);
	}

	&__inner {
	}
	// Text half
	.banner-text {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@include breakpoint-lg {
			@include padding(b, 9);
			@include padding(t, 10);
			@include margin(t, 8);
		}

		.text-underline {
			&::after {
				bottom: 4px;
				height: 6px;

				@include breakpoint-lg {
					bottom: 8px;
				}
			}
		}

		&__arrow {
			@include breakpoint-lg {
				position: absolute;
				top: 0;
				right: 0;

				width: 12vw;
				height: 9vw;

				@include bg-img("dashed_arrow.svg");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;

				transform: translate(-20%, 0%);
			}

			@include breakpoint-2xl {
				width: 14vw;
				height: 10.6vw;

				max-width: 240px;
			}
		}

		// Editable content
		&__inner {
			@include margin(b, 7);
			margin-right: auto;

			.heading {
				@include margin(b, 5);
			}

			.subheading {
				@include margin(b, 6);
			}
		}
	}

	// Video half of banner
	.banner-video {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		@include phone-video;

		// Background image, pinned to right side
		&__background {
			@include margin(b, 8);
			@include pin-right;
			width: 100%;

			@include breakpoint-lg {
				width: 120%;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		&__video {
			margin-top: -50%;
		}

		.sparkle {
			display: none;
			@include breakpoint-lg {
				display: block;
			}
		}
	}
}

// Animation START
.banner-animation {
	position: relative;
	margin: 0 auto;

	display: flex;
	justify-content: center;

	width: 100%;
	max-width: min(42vw, 306px);

	@include breakpoint-lg() {
		max-width: 306px;
	}

	img {
		object-fit: contain;
		object-position: center;
		width: 100%;
		height: auto;
	}

	&__phone {
		position: relative;

		& > img {
			width: 100%;
			filter: drop-shadow(-11px 16px 18px rgba(#d3d7dc, 0.8));
		}

		div[class^="banner-animation__"] {
			position: absolute;
		}
	}

	&__home-notifications {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		filter: drop-shadow(0 0 49px rgba(black, 0.1));
	}

	&__home-notification {
		top: phone-h-relative(165);
		left: phone-w-relative(26);
		right: phone-w-relative(26);
		height: phone-h-relative(73.72);
		opacity: 0;
	}

	&__home-notification-2 {
		top: phone-h-relative(249);
		left: phone-w-relative(26);
		right: phone-w-relative(26);
		height: phone-h-relative(73.72);
		opacity: 0;
		// display: none;
	}

	&__home-notification-3 {
		top: phone-h-relative(333);
		left: phone-w-relative(26);
		right: phone-w-relative(26);
		height: phone-h-relative(73.72);
		opacity: 0;
		// display: none;
	}

	&__home-notification-4 {
		top: phone-h-relative(417);
		left: phone-w-relative(26);
		right: phone-w-relative(26);
		height: phone-h-relative(73.72);
		opacity: 0;
		// display: none;
	}

	&__confirmation {
		background: white;
		border-radius: 10px;

		top: phone-h-relative(165);
		left: phone-w-relative(26);
		right: phone-w-relative(26);
		height: phone-h-relative(73.72);

		// filter: drop-shadow(0 0 40px rgba(black, 0.1));

		opacity: 0;

		img {
			opacity: 0;
		}
	}

	&__signature-text {
		left: phone-w-relative(101);
		right: phone-w-relative(101);
		height: phone-h-relative(17);
		top: phone-h-relative(370);
		opacity: 0;
	}

	&__signature-box {
		opacity: 0;
		top: phone-h-relative(397);
		left: phone-w-relative(26);
		right: phone-w-relative(26);
		height: phone-h-relative(152);

		filter: drop-shadow(0 0 53px rgba(black, 0.1));

		svg {
			position: absolute;
			left: 50%;
			top: 50%;

			width: phone-w-relative(160);

			transform: translate(-50%, -50%);
		}
	}

	&__qr-code {
		// opacity: 0;
		left: phone-w-relative(-129);
		top: phone-h-relative(57);

		width: phone-w-relative(147);
		height: phone-h-relative(115);

		filter: drop-shadow(0 0 46px rgba(black, 0.1));
		opacity: 0;
	}

	&__calendar-container {
		left: phone-w-relative(-176);
		top: phone-h-relative(267);
		width: phone-w-relative(207);
		height: phone-h-relative(341);
		opacity: 0;
	}

	&__calendar {
		// opacity: 0;
		left: 0;
		top: 0;
		width: 100%;
		height: calc(100% * 251 / 340);

		filter: drop-shadow(0 0 40px rgba(black, 0.1));
	}

	&__calendar-notification {
		opacity: 0;
		left: 0;
		top: calc(100% * 281 / 341);
		width: 100%;
		height: calc(100% * 60 / 341);
		filter: drop-shadow(0px 0px 40px rgba(black, 0.1));
	}

	&__expenses {
		// opacity: 0;
		right: phone-w-relative(-172);
		top: phone-h-relative(417);

		width: phone-w-relative(229);
		height: phone-h-relative(197);

		filter: drop-shadow(0 0 40px rgba(black, 0.1));
		overflow: hidden;
		opacity: 0;
	}

	&__expenses-2 {
		opacity: 0;
		top: calc(100% * 79 / 197);
		left: calc(100% * 5 / 229);
		right: calc(100% * 5 / 229);
		height: calc(100% * 54 / 197);
	}

	&__expenses-3 {
		opacity: 0;
		top: calc(100% * 132 / 197);
		left: calc(100% * 5 / 229);
		right: calc(100% * 5 / 229);
		height: calc(100% * 54 / 197);
	}

	&__tapinout {
		top: phone-h-relative(127);
		left: 90%;
		width: phone-w-relative(241);
		height: phone-h-relative(160);
	}

	&__tapinout-collapsed {
		// opacity: 0;
		top: 0;
		left: 0;
		width: calc(100% * 116 / 241);
		height: calc(100% * 65 / 160);

		filter: drop-shadow(0 0 46px rgba(black, 0.1));
		opacity: 0;
	}

	&__tapinout-expanded {
		opacity: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		filter: drop-shadow(0 0 40px rgba(black, 0.2));
	}
}

// Animation END
