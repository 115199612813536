.newsletter-subscription {
	@include padding(y, 8);

	&__inner {
		display: flex;
		flex-direction: column;
		@include breakpoint-md {
			flex-direction: row;
			align-items: baseline;
			justify-content: center;
			margin: 0 auto;
			max-width: 60%;
			min-width: 700px;
		}

		.heading {
			@include margin(b, 5);
			color: $text-black;
			font-weight: 700;
			font-size: 26px;

			@include breakpoint-md {
				@include margin(r, 7);
				@include margin(b, 0);
				font-size: 30px;
				flex-grow: 1;
				text-align: right;
			}
		}

		.field-container {
			flex-grow: 1;

			// .btn--subscribe {
			// 	@include margin(r, 1);
			// 	position: absolute;
			// 	right: 0;
			// 	top: 50%;

			// 	transform: translateY(-50%);
			// 	box-shadow: none;

			// 	@include padding(l, 4);
			// 	@include padding(r, 6);

			// 	&::after {
			// 		left: auto;
			// 		right: 0;
			// 	}

			// 	&:hover::before {
			// 		box-shadow: none;
			// 	}
			// }
		}
	}
}
