.fifty-fifty {
	@include padding(y, 6);

	&__inner {
		display: flex;
		flex-direction: column;

		@include breakpoint-lg {
			flex-direction: row;
		}
	}

	&--reversed {
		.fifty-fifty__inner {
			flex-direction: column-reverse;

			@include breakpoint-lg {
				flex-direction: row-reverse;
			}
		}
	}

	&--reversed-lg {
		.fifty-fifty__inner {
			@include breakpoint-lg {
				flex-direction: row-reverse;
			}
		}
	}

	&__block {
		@include padding(b, 6);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__image-block {
		width: 100%;

		img {
			width: 100%;
		}
	}

	&__text {
		max-width: 60ch;
		@include breakpoint-lg {
			max-width: 55ch;
		}
		.heading {
			@include margin(b, 4);
		}
		.text-copy {
			@include margin(b, 5);
		}
	}

	.fifty-fifty-tag {
		@include margin(b, 4);
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		&__image {
			@include margin(r, 2);
		}
	}
	.fifty-fifty-video {
		width: 90%;
		@include phone-video;
		@include margin(b, 8);

		@include breakpoint-lg {
			width: 100%;
		}

		&__video {
			margin-top: -66%;
		}

		&__background {
			width: 100%;

			img {
				width: 100%;
			}
		}
	}
}
