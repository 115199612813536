.lightbox {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	background: rgba(0, 0, 0, 0.6);

	z-index: 20;

	&__close-button {
		position: absolute;
		top: 2%;
		right: 2%;

		width: 40px;
		height: 40px;

		@include bg-img("icons/close-white.svg");
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
	}

	&__video-container {
		position: relative;
		border: 2px solid $access-teal;
		border-radius: $border-radius;
		display: flex;
		justify-content: center;
		align-items: center;

		width: 90%;
		height: auto;

		box-shadow: 0px 12px 20px $access-teal-darker-20;
		// max-height: 80vh;

		@include breakpoint-lg {
			max-width: 650px;
			// max-height: 70vh;
		}

		video,
		iframe {
			width: 100%;
			height: auto;
		}
	}
}
