html,
body {
	font-family: $font-family;
	// overflow-x: hidden;
	width: 100%;
	color: $text-black;
}

a {
	display: inline-block;
}

section {
	overflow: hidden;
}

// Fix for Safari border radius with background colour + background image
*,
*::before,
*::after {
	background-clip: border-box;
}
