.feature-listing {
	@include padding(y, 7);
	@include breakpoint-lg {
		@include padding(y, 10);
	}

	&__inner {
		.heading {
			@include margin(b, 5);

			& + p {
				max-width: 80ch;
				margin-right: auto;
				margin-left: auto;
			}
		}
	}

	&__image {
		@include margin(t, 8);

		width: 100%;

		img {
			width: 100%;
		}

		@include breakpoint-lg {
			@include margin(t, 9);
		}
	}

	&__grid {
		@include margin(t, 8);
		display: grid;
		grid-template-columns: 1fr;

		// Max height set in javascript (expander.js)
		// max-height: 260px;

		overflow: hidden;

		& > * {
			@include margin(b, 7);
		}

		@include breakpoint-lg {
			@include margin(t, 9);
			max-height: none;

			&.grid-columns--2 {
				grid-template-columns: repeat(2, 1fr);

				& > * {
					@include margin(r, 8);
					@include margin(b, 8);
				}
			}
			&.grid-columns--3 {
				grid-template-columns: repeat(3, 1fr);

				& > * {
					@include margin(r, 8);
					@include margin(b, 8);
				}
			}
		}
	}

	&__see-more {
		text-align: center;
		button {
			@include margin(t, 6);
		}
	}

	.feature-listing-grid-item {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: center;

		@include breakpoint-lg {
			flex-direction: column;
			align-items: flex-start;
		}

		&__icon {
			@include margin(r, 2);
			@include margin(b, 3);
			width: 30px;

			img {
				width: 100%;
			}
		}

		&__title {
			@include margin(b, 3);
			font-weight: bold;
			font-size: 18px;
			color: $text-black;
			line-height: 1;

			@include breakpoint-lg {
				@include margin(b, 2);
			}
		}

		&__description {
			font-family: $font-family;
			font-weight: 400;
			font-size: 14px;
			line-height: 2;
		}
	}
}
