/* Breakpoints START */
@mixin breakpoint-xs {
	@media (min-width: $bp-xs) {
		@content;
	}
}
@mixin breakpoint-sm {
	@media (min-width: $bp-sm) {
		@content;
	}
}
@mixin breakpoint-less-than-sm {
	@media (max-width: ($bp-sm - 1px)) {
		@content;
	}
}

@mixin breakpoint-md {
	@media (min-width: $bp-md) {
		@content;
	}
}
@mixin breakpoint-less-than-md {
	@media (max-width: ($bp-md -1px)) {
		@content;
	}
}

@mixin breakpoint-lg {
	@media (min-width: $bp-lg) {
		@content;
	}
}
@mixin breakpoint-less-than-lg {
	@media (max-width: ($bp-lg - 1px)) {
		@content;
	}
}

@mixin breakpoint-xl {
	@media (min-width: $bp-xl) {
		@content;
	}
}
@mixin breakpoint-less-than-xl {
	@media (max-width: ($bp-xl - 1)) {
		@content;
	}
}

@mixin breakpoint-2xl {
	@media (min-width: $bp-2xl) {
		@content;
	}
}
@mixin breakpoint-less-than-2xl {
	@media (max-width: ($bp-2xl - 1px)) {
		@content;
	}
}
/* Breakpoints END */

/* Grid Columns START */
@mixin col-props {
	box-sizing: border-box;
}

/* Grid Columns END */

@mixin bg-img($path) {
	$url: $IMAGE_PATH + $path;
	background-image: url("#{$url}");
}

/* Colours START */

@mixin text-black {
	color: $text-black;
}
@mixin text-teal {
	color: $text-teal;
}
@mixin text-red {
	color: $text-red;
}
/* Colours END */

/* Spacings START */
@mixin padding($direction, $n) {
	@if $direction == "l" {
		@if $n > 0 {
			padding-left: nth($spacings, $n);
		} @else {
			padding-left: 0;
		}
	} @else if $direction == "t" {
		@if $n > 0 {
			padding-top: nth($spacings, $n);
		} @else {
			padding-top: 0;
		}
	} @else if $direction == "r" {
		@if $n > 0 {
			padding-right: nth($spacings, $n);
		} @else {
			padding-right: 0;
		}
	} @else if $direction == "b" {
		@if $n > 0 {
			padding-bottom: nth($spacings, $n);
		} @else {
			padding-bottom: 0;
		}
	} @else if $direction == "x" {
		@if $n > 0 {
			padding-left: nth($spacings, $n);
			padding-right: nth($spacings, $n);
		} @else {
			padding-left: 0;
			padding-right: 0;
		}
	} @else if $direction == "y" {
		@if $n > 0 {
			padding-top: nth($spacings, $n);
			padding-bottom: nth($spacings, $n);
		} @else {
			padding-top: 0;
			padding-bottom: 0;
		}
	} @else {
		@if $n > 0 {
			padding: nth($spacings, $n);
		} @else {
			padding: 0;
		}
	}
}
@mixin margin($direction, $n) {
	@if $direction == "l" {
		@if $n > 0 {
			margin-left: nth($spacings, $n);
		} @else if $n < 0 {
			margin-left: -#{nth($spacings, abs($n))};
		} @else {
			margin-left: 0;
		}
	} @else if $direction == "t" {
		@if $n > 0 {
			margin-top: nth($spacings, $n);
		} @else if $n < 0 {
			margin-top: -#{nth($spacings, abs($n))};
		} @else {
			margin-top: 0;
		}
	} @else if $direction == "r" {
		@if $n > 0 {
			margin-right: nth($spacings, $n);
		} @else if $n < 0 {
			margin-right: -#{nth($spacings, abs($n))};
		} @else {
			margin-right: 0;
		}
	} @else if $direction == "b" {
		@if $n > 0 {
			margin-bottom: nth($spacings, $n);
		} @else if $n < 0 {
			margin-bottom: -#{nth($spacings, abs($n))};
		} @else {
			margin-bottom: 0;
		}
	} @else if $direction == "x" {
		@if $n > 0 {
			margin-left: nth($spacings, $n);
			margin-right: nth($spacings, $n);
		} @else if $n < 0 {
			margin-left: -#{nth($spacings, abs($n))};
			margin-right: -#{nth($spacings, abs($n))};
		} @else {
			margin-left: 0;
			margin-right: 0;
		}
	} @else if $direction == "y" {
		@if $n > 0 {
			margin-top: nth($spacings, $n);
			margin-bottom: nth($spacings, $n);
		} @else if $n < 0 {
			margin-top: -#{nth($spacings, abs($n))};
			margin-bottom: -#{nth($spacings, abs($n))};
		} @else {
			margin-top: 0;
			margin-bottom: 0;
		}
	} @else {
		@if $n > 0 {
			margin: nth($spacings, $n);
		} @else {
			margin: 0;
		}
	}
}
/* Spacings END */

@mixin scaledToWidth($baseWidth, $baseHeight, $targetWidth) {
	$ratio: $baseWidth / $baseHeight;
	$width: $targetWidth;
	width: $width * 1px;
	height: $width * $ratio * 1px;
}

@mixin phone-video {
	// Phone video container
	&__video {
		width: 100%;
		position: relative;

		// Based on figma dimensions
		$phone-ratio: (158.43 / 316.91);

		// Based on SVG element
		// $phone-ratio: (365 / 729);

		padding-top: calc(100% * #{$phone-ratio});

		@include bg-img("phone.svg");
		background-position: center;

		// Phone SVG stretches naturally
		background-size: 100% 100%;
		background-repeat: no-repeat;

		// Recreate drop-shadow
		border-radius: 32px;
		box-shadow: -12px 16px 18px rgba(211, 215, 220, 0.8);

		// For sparkle child animation
		z-index: 0;
	}

	// Positioning inside the phone image
	video,
	&__placeholder {
		position: absolute;
		left: 7.01%;
		top: 4.5%;

		width: 87.14%;
		height: 92%;

		border-radius: $border-radius;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		display: flex;
		justify-content: center;
		align-items: center;
	}
	// Darken image
	&__placeholder::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background-color: rgba(0, 0, 0, 0.3);
	}
}

@mixin fade-edges {
	// "Fade" colour effect for each block colour
	&::after,
	&::before {
		content: "";
		position: absolute;
		top: -10%;
		bottom: -10%;
		// left: 0;
		z-index: 1;
		width: 25%;

		pointer-events: none;

		opacity: 1;
		visibility: visible;
		transition: opacity 350ms ease-in-out, visibility 350ms ease-in-out;
	}

	&::after {
		left: 0;
		display: none;
		@include breakpoint-lg {
			display: block;
		}
	}

	&::before {
		right: 0;
	}

	.block--primary & {
		$primary-faded--full: transparentize($bg-primary, 1);
		$primary-faded--half: transparentize($bg-primary, 0.5);
		&::after {
			left: 0;
			background: linear-gradient(to left, $primary-faded--full, $primary-faded--half);
		}

		&::before {
			right: 0;
			background: linear-gradient(to right, $primary-faded--full, $primary-faded--half);
		}
	}
	.block--secondary & {
		$secondary-faded--full: transparentize($bg-secondary, 1);
		$secondary-faded--half: transparentize($bg-secondary, 0.5);
		&::after {
			left: 0;
			background: linear-gradient(to left, $secondary-faded--full, $secondary-faded--half);
		}

		&::before {
			right: 0;
			background: linear-gradient(to right, $secondary-faded--full, $secondary-faded--half);
		}
	}
	.block--tertiary & {
		$tertiary-faded--full: transparentize($bg-tertiary, 1);
		$tertiary-faded--half: transparentize($bg-tertiary, 0.5);
		&::after {
			left: 0;
			background: linear-gradient(to left, #{$tertiary-faded--full}, #{$tertiary-faded--half});
		}

		&::before {
			right: 0;
			background: linear-gradient(to right, #{$tertiary-faded--full}, #{$tertiary-faded--half});
		}
	}
}
