.short-banner {
	background-color: $bg-secondary;

	&__heading {
		@include padding(t, 8);
		@include padding(b, 9);
		position: relative;
		overflow: hidden;
		z-index: 0;
		text-align: center;

		@include breakpoint-lg {
			width: 100%;
			@include padding(t, 8);
			@include padding(b, 13);
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;

			$l: 120vw;
			width: $l;
			height: $l;

			$max-l: 800px;
			max-width: $max-l;
			max-height: $max-l;

			background-color: $access-teal;
			opacity: 0.06;

			transform: translate(-60%, 65%) rotate(35deg);

			@include breakpoint-lg {
				width: 25vw;
				height: 25vw;
				transform: translate(-50%, 50%) rotate(45deg);
			}
		}

		&::after {
			display: none;
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;

			width: 35vw;
			height: 35vw;

			background-color: $access-teal;
			opacity: 0.06;

			transform: translate(50%, 40%) rotate(65deg);
			@include breakpoint-lg {
				display: block;
			}
		}

		.heading {
			@include margin(b, 4);
			font-weight: bold;
			font-size: 36px;
			color: $text-black;

			@include breakpoint-lg {
				font-size: 68px;
			}
		}

		.subheading {
			font-size: 14px;
			font-weight: normal;
			color: $text-black;

			max-width: 35ch;
			margin-left: auto;
			margin-right: auto;

			@include breakpoint-lg {
				font-size: 18px;
				max-width: 60ch;
			}
		}
	}

	&__content {
		display: flex;
		justify-content: center;
		background: $white;
	}

	&__inner {
		background-color: $white;
		z-index: 0;
		@include breakpoint-lg {
			position: relative;

			display: flex;
			justify-content: stretch;

			$y-shift: -1 * nth($spacings, 12);
			margin-top: $y-shift;
		}
	}
}
