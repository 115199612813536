$input-field-shadow: inset 0 0 0 1px;

@mixin field-input {
	@include padding(y, 3);
	@include padding(x, 4);

	display: inline-flex;
	flex-grow: 1;
	outline: none;

	color: $text-black;
	font-size: 14px;
	font-family: $font-family;
	font-weight: bold;
	line-height: 1.5;
	background-color: white;

	&:focus {
		outline: none;
		border: none;
		box-shadow: #{$input-field-shadow} $access-teal;
	}

	&::placeholder {
		color: transparentize($text-black, 0.1);
		font-weight: normal;
	}

	border-radius: 100px;
	box-shadow: #{$input-field-shadow} $form-border-colour;

	&[type="number"]::-webkit-inner-spin-button,
	&[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
}

@mixin field-label {
	@include margin(b, 3);
	font-size: 16px;
	font-weight: bold;
	line-height: 1;
	color: $text-black;
}

.field-container,
.mktoFieldWrap.mktoFieldWrap {
	// @include padding(b, 4);
	@include margin(b, 6);
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100% !important;

	.field-label {
		@include field-label;
	}

	.field-input {
		@include field-input;

		&--subscribe {
			// Space for the subscription button
			padding-right: 110px;
		}
	}

	.field-radio {
		@include margin(b, 4);
		input[type="radio"],
		input[type="checkbox"] {
			display: none;

			// Selected radio option styles
			&:checked + span {
				font-weight: bold;
				color: $access-teal;

				&::before {
					border-color: $access-teal;
				}

				// Solid radio check circle
				&::after {
					content: "";
					position: absolute;
					left: 5px;

					width: 15px;
					height: 15px;
					background: $access-teal;
					border-radius: 50%;
					flex: 0 0 auto;
				}
			}
		}
		// Radio label text
		span {
			position: relative;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;

			font-size: 16px;
			color: transparentize($text-black, 0.5);
			line-height: 1;
			font-weight: normal;

			cursor: pointer;

			// Radio check background
			&::before {
				@include margin(r, 3);
				content: "";
				position: relative;
				width: 25px;
				height: 25px;
				background: transparent;
				border: 1px solid $form-border-colour;
				border-radius: 50%;
				flex: 0 0 auto;
			}
		}
	}

	.field-select {
		position: relative;
		display: flex;
		&::after {
			@include margin(r, 4);
			@include margin(t, 1);
			position: absolute;
			content: "";

			width: 15px;
			height: 9px;

			right: 0;
			top: 50%;

			@include bg-img("icons/chevron_down-black.svg");
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: center;

			transform: translateY(-50%);
		}

		select {
			@extend .field-input;
			// @include padding(y, 4);
			line-height: 1.5;
			appearance: none;
			color: transparentize($text-black, 0.5);

			&:required:invalid {
				color: transparentize($text-black, 0.5);
			}

			option[value=""][disabled] {
				display: none;
			}

			option {
				color: $text-black;

				&:first-child {
					font-weight: normal;
				}
			}
		}
	}

	/******
	Error styles
	*****/

	&.field--error {
		@include margin(b, 8);
		.field-input {
			box-shadow: #{$input-field-shadow} $text-red;
			// border-width: 2px;
		}

		&::after {
			@include margin(t, 4);
			@include margin(r, 4);

			content: "";
			width: 20px;
			height: 20px;

			position: absolute;
			right: 0;
			top: 50%;

			transform: translateY(-50%);

			@include bg-img("icons/warning.svg");
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
		}

		.error-message {
			display: block;
		}
	}

	.error-message {
		@include margin(t, 2);

		display: none;

		position: absolute;
		left: 0;
		top: 100%;

		font-size: 14px;
		color: $text-red;
	}
}

// MktoForms2
.mktoForm.mktoForm {
	font-family: $font-family !important;
	font-size: 16px !important;
	color: $text-black !important;
	width: auto !important;

	// display: flex;
	// flex-direction: column;

	.mktoFormRow {
		.mktoPlaceholder {
			@include margin(t, -6);
			display: none;
		}

		.mktoFormCol {
			margin-bottom: 0 !important;
			width: 100% !important;

			.mktoOffset {
				display: none !important;
			}

			.mktoFieldWrap {
				@include margin(b, 6);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				width: 100% !important;

				.mktoGutter {
					display: none !important;
				}

				.mktoLabel {
					@include field-label;
					padding: 0;
					display: flex;
					flex-direction: row-reverse;
					float: none;
					justify-content: flex-end;
					width: auto !important;

					.mktoAsterix {
						@include padding(x, 1);
					}
				}

				.mktoField:not(input[type="radio"]):not(input[type="checkbox"]) {
					@include field-input;
					height: auto;
					width: 100% !important;
				}

				.mktoCheckboxList {
					display: flex;
					align-items: flex-start;

					& > label {
						padding: 0;
						// @include padding(l, 4);
						margin: 0;
					}
				}

				.field-select {
					display: flex;
					flex-direction: column;
					width: 100%;

					.mktoError {
						position: absolute;
						top: 100%;
					}
				}
			}

			// Error Message Styling
			.mktoError {
				@include margin(t, 2);
				position: relative;
				right: auto !important;
				bottom: auto !important;
				z-index: 5;
				.mktoErrorArrowWrap {
					display: none;
				}
				.mktoErrorMsg {
					background: transparent;
					text-shadow: none;
					box-shadow: none;
					border: none;
					color: $text-red;
					padding: 0;
					margin: 0;
					font-size: 14px;
					max-width: none;
				}
			}
		}
	}

	.mktoButtonRow {
		width: 100%;
		text-align: center;

		.mktoButtonWrap {
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}
}
