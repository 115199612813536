.benefits {
	@include padding(t, 9);
	@include padding(b, 6);

	&__inner {
	}

	&__content {
		@include padding(y, 6);

		@include breakpoint-lg {
			@include padding(y, 8);
		}
	}

	&__heading {
		@include margin(b, 4);

		.heading {
			font-size: 30px;

			@include breakpoint-lg {
				@include margin(b, 6);
				font-size: 42px;
			}
		}
	}

	&__image {
		width: 100%;

		img {
			width: 100%;
		}
	}

	&__grid {
		@include margin(b, 6);
		display: grid;
		grid-template-columns: repeat(2, 1fr);

		@include breakpoint-lg {
			max-width: 65ch;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.benefits-grid-item {
		@include margin(b, 6);
		@include margin(r, 5);
		&__stats {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: baseline;

			font-size: 34px;
			color: $text-teal;
			font-weight: bold;
		}

		&__description {
			font-size: 14px;
			color: $white;
		}
	}
}
