.tooltip {
	@include margin(r, 6);
	position: relative;
	display: inline-flex;
	width: 20px;
	height: 20px;
	@include bg-img("icons/info-grey.svg");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	flex-shrink: 0;

	&__content {
		@include padding(x, 3);
		@include padding(y, 2);

		display: none;

		background-color: $white;
		border-radius: 6px;

		box-shadow: -6px 6px 30px rgba(0, 0, 0, 0.1);

		font-size: 12px;
		color: $text-black;
		white-space: nowrap;
		// z-index: 0;

		&[data-show] {
			display: block;
		}
		&::before {
			content: "";
			position: absolute;
			width: 12px;
			height: 12px;

			background: inherit;

			top: 50%;
			left: 0;
			transform: translate(-50%, -50%) rotate(45deg);

			// box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
			// z-index: -1;
		}

		// &[data-popper-placement^="top"]::before {
		// 	left: 50%;
		// 	bottom: 0;
		// 	transform: translate(-50%, 50%) rotate(45deg);
		// }

		// &[data-popper-placement^="bottom"]::before {
		// 	left: 50%;
		// 	top: 0;
		// 	transform: translate(-50%, -50%) rotate(45deg);
		// }

		// &[data-popper-placement^="left"]::before {
		// 	top: 50%;
		// 	right: 0;
		// 	transform: translate(50%, -50%) rotate(45deg);
		// }

		// &[data-popper-placement^="right"]::before {
		// 	top: 50%;
		// 	left: 0;
		// 	transform: translate(-50%, -50%) rotate(45deg);
		// }
	}
}
