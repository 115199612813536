.alternate-journey {
	@include padding(y, 7);

	@include breakpoint-lg {
		@include padding(y, 9);
	}

	&__inner {
		.heading {
			@include margin(b, 8);

			@include breakpoint-lg {
				@include margin(b, 9);
			}
		}
	}

	.alternate-journey-cards {
		display: grid;
		grid-template-columns: 1fr;

		@include breakpoint-lg {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	.alternate-journey-card {
		@include margin(b, 7);
		position: relative;
		box-shadow: 0 12px 22px 0 rgba(211, 215, 220, 0.8);
		border-radius: $border-radius;
		background-color: $white;

		color: transparentize($text-black, 0.3);

		width: 100%;
		// overflow: hidden;

		z-index: 0;

		@include breakpoint-lg {
			max-width: 380px;

			&:first-child {
				margin-left: auto;
				@include margin(r, 4);
			}

			&:last-child {
				@include margin(l, 4);
				margin-right: auto;
			}
		}
		@include breakpoint-xl {
			max-width: 460px;

			&:first-child {
				margin-left: auto;
				@include margin(r, 6);
			}

			&:last-child {
				@include margin(l, 6);
				margin-right: auto;
			}
		}

		&__inner {
			@include padding(x, 5);
			@include padding(y, 5);
		}

		&__image {
			@include margin(b, 4);
			width: 100%;
			height: 260px;
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;

			@include breakpoint-lg {
				height: 280px;
			}
			@include breakpoint-xl {
				height: 340px;
			}
		}

		&__title {
			@include margin(b, 2);
			color: $text-black;
			font-size: 18px;
			font-weight: bold;
			line-height: 1;
		}

		&__description {
			@include margin(b, 3);
			color: $text-black;
			font-size: 14px;
			line-height: 2;
		}

		.link-block {
			&__button {
				border-color: transparentize($grey-dark, 0.3);
				svg {
					path {
						stroke: transparentize($grey-dark, 0.3);
					}
				}
			}
		}
		&:hover {
			.link-block {
				background-color: $access-teal;
				color: white;

				&__button {
					background-color: white;
					border-color: transparent;
					svg path {
						stroke: $access-teal;
					}
				}
			}
		}

		// Sparkle animation on hover
		$sparkle-open-transition: 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
		$sparkle-close-transition: 0.15s linear;
		&:first-child .sparkle {
			@include breakpoint-lg {
				@include bg-img("sparkle-2.svg");

				transform-origin: 5% 75%;
				transform: scale(0);
				transition: transform #{$sparkle-close-transition};

				bottom: 95%;
				right: 80%;

				@include scaledToWidth(114, 112, 70);
			}

			@include breakpoint-xl {
				@include scaledToWidth(114, 112, 90);
			}
		}

		&:last-child .sparkle {
			@include breakpoint-lg {
				@include bg-img("sparkle-2.svg");

				transform-origin: 5% 75%;
				transform: scale(0);
				transition: transform #{$sparkle-close-transition};
				bottom: 96%;
				left: 98%;

				@include scaledToWidth(114, 112, 70);
			}

			@include breakpoint-xl {
				@include scaledToWidth(114, 112, 90);
			}
		}

		&:first-child:hover {
			.sparkle {
				transform: scaleX(-1) scaleY(1);
				transition: transform #{$sparkle-open-transition};
			}
		}
		&:last-child:hover {
			.sparkle {
				transform: scale(1);
				transition: transform #{$sparkle-open-transition};
			}
		}
	}
}
