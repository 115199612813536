.container {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;

	//  Create container style for each breakpoint
	@each $size, $min-width in $breakpoints {
		@media (min-width: $min-width) {
			max-width: map-get($max-widths, $size);

			&--full {
				max-width: none;
			}
		}
	}
	@each $size, $min-width in $breakpoints {
		@media (min-width: $min-width) {
			&-#{$size}--full {
				max-width: none;
			}
		}
	}
}

.row {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	margin-left: -#{map-get($grid-gutter, xs) / 2};
	margin-right: -#{map-get($grid-gutter, xs) / 2};

	@each $size, $gutter in $grid-gutter {
		$min-width: map-get($breakpoints, $size);
		@media (min-width: $min-width) {
			margin-left: -#{$gutter / 2};
			margin-right: -#{$gutter / 2};
		}
	}
}

@mixin column-padding {
	padding-left: #{map-get($grid-gutter, xs) / 2};
	padding-right: #{map-get($grid-gutter, xs) / 2};
	@each $size, $gutter in $grid-gutter {
		$min-width: map-get($breakpoints, $size);
		@media (min-width: $min-width) {
			padding-left: #{$gutter / 2};
			padding-right: #{$gutter / 2};
		}
	}
}
.col {
	flex: 0 0 100%;

	@include column-padding();
}

@each $size, $min-width in $breakpoints {
	@media (min-width: $min-width) {
		.col-#{$size}-auto {
			@include col-props();
			flex: 1 1 0;
		}
		.col-#{$size}-full {
			@include col-props();
			flex: 1 0 100;
		}

		.col-#{$size}-offset-0 {
			@include col-props();
			margin-left: 0;
		}

		@for $i from 1 through $grid-columns {
			$perc: ($i / $grid-columns) * 100%;
			.col-#{$size}-#{$i} {
				@include col-props();
				flex: 0 0 $perc;
			}
			.col-#{$size}-offset-#{$i} {
				@include col-props();
				margin-left: $perc;
			}
			.col-#{$size}-pull-#{$i} {
				@include col-props();
				margin-left: -1 * $perc;
			}
		}
	}
}

// To pin to the right hand side of the window when inside of a container
@mixin pin-right {
	@each $size, $gutter in $grid-gutter {
		$min-width: map-get($breakpoints, $size);
		$offset: #{$gutter / 2};
		$diff: calc(100vw - #{map-get($max-widths, $size)});
		@media (min-width: $min-width) {
			margin-right: calc(-0.5 * #{$diff});
			margin-left: auto;
		}
	}
}
// To pin to the left hand side of the window when inside of a container
@mixin pin-left {
	@each $size, $gutter in $grid-gutter {
		$min-width: map-get($breakpoints, $size);
		$offset: #{$gutter / 2};
		$diff: calc(100vw - #{map-get($max-widths, $size)});

		@media (min-width: $min-width) {
			margin-left: calc(-0.5 * #{$diff});
			margin-right: auto;
		}
	}
}

@mixin pin-both {
	@each $size, $min-width in $breakpoints {
		$gutter: map-get($grid-gutter, $size);

		@media (min-width: $min-width) {
			$m: 0;
			@if $size == "xs" {
				$m: -0.5 * $gutter;
			} @else {
				$m: calc(-0.5 * (100vw - #{map-get($breakpoints, $size)} + #{$gutter/2}));
			}
			margin-left: $m;
			margin-right: $m;
		}
	}
}
