.integrations {
	@include padding(t, 8);
	@include padding(b, 9);
	display: flex;
	flex-direction: column;

	&__heading {
		.heading {
			@include margin(b, 4);
		}

		.subheading {
			@include margin(b, 6);
			max-width: 80ch;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__slides {
		@include margin(t, 7);
		@include margin(b, 7);
		// @include margin(x, -4);
		// @include padding(x, 4);

		@include breakpoint-lg {
			@include margin(x, 0);
			max-width: 100vw;
		}
		// display: flex;

		&-container {
			position: relative;

			@include fade-edges;

			@include breakpoint-lg {
				@include padding(t, 6);
			}

			&::before {
				display: none;

				@include breakpoint-lg {
					display: block;
				}
			}

			&::after {
				display: none;

				@include breakpoint-lg {
					display: block;
				}
			}
		}
		// To show box-shadow
		.slick-list {
			overflow: visible;
		}
	}
}

.integration-slide {
	@include margin(b, 6);
	@include margin(x, 4);
	box-shadow: 6px 6px 12px transparentize(#d3d7dc, 0.2);
	overflow: visible;
	border-radius: $border-radius;

	@include breakpoint-lg {
		@include margin(x, 3);
		display: block;
	}

	&-wrapper {
		// To handle collapsing margins, so height of parent div includes child margin
		overflow: auto;
	}

	&__inner {
		display: flex;
		flex-direction: row;

		background-color: white;
		border-radius: $border-radius;
		overflow: hidden;

		@include breakpoint-lg {
			flex-wrap: wrap;
		}
	}

	&__header {
		display: flex;
		flex-direction: row;
		flex-grow: 1;
	}

	&__heading {
		@include padding(x, 4);
		@include padding(y, 5);

		flex-grow: 1;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		@include breakpoint-lg {
			@include padding(x, 5);
			@include padding(t, 6);
			@include padding(b, 0);
			align-items: center;
			justify-content: space-between;
		}

		img {
			@include margin(b, 2);
			flex-shrink: 0;

			@include breakpoint-lg {
				max-width: 140px;
			}
		}

		& > * + * {
			@include margin(t, 4);

			@include breakpoint-lg {
				@include margin(t, 0);
			}
		}
	}

	&__heading-icon {
		@include padding(x, 4);
		@include padding(y, 4);

		display: flex;
		justify-content: center;
		align-items: center;

		flex-shrink: 0;
		width: 72px;

		background: $bg-secondary;

		transition: all 250ms ease-in-out;

		@include breakpoint-lg {
			@include padding(x, 5);

			width: 100%;

			order: 10;

			justify-content: space-between;

			font-weight: bold;
		}

		&::after {
			content: "";

			width: 34px;
			height: 34px;

			border: 1px solid $grey-dark;
			border-radius: 50%;
			opacity: 0.7;

			@include bg-img("icons/arrow-grey.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: 14px auto;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		background: rgba(196, 196, 196, 0.3);

		@include breakpoint-lg {
			@include margin(y, 4);
		}
	}

	.title {
		font-size: 16px;
		color: transparentize($text-black, 0.5);
		font-weight: normal;
		display: inline-block;
		margin-right: auto;
	}

	&__content {
		@include padding(x, 5);
		@include padding(b, 6);
		// width: 100%;
		flex-basis: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		p {
			@include margin(t, 3);
		}
	}

	// Re-ordering elements to match changing design mobile <-> desktop
	&__heading img {
		order: 1;
	}

	.divider {
		order: 2;
	}
	.title {
		order: 3;
	}
	.tag {
		order: 4;
	}

	@include breakpoint-lg {
		&__heading img {
			order: 1;
		}
		.tag {
			order: 2;
		}

		.divider {
			order: 3;
		}
		.title {
			order: 4;
		}

		&:hover &__heading-icon,
		&:focus &__heading-icon {
			background: $access-teal;
			color: white;
			&::after {
				@include bg-img("icons/arrow-teal.svg");
				border-color: white;
				background-color: white;
				opacity: 1;
			}
		}
	}
}
