.block {
	&--primary {
		background-color: $bg-primary;
	}

	&--secondary {
		background-color: $bg-secondary;
	}
	&--tertiary {
		background-color: $bg-tertiary;
	}
}

.text-underline {
	position: relative;
	z-index: 0;
	white-space: nowrap;
	&::after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0px;
		height: 4px;
		width: 100%;

		@include bg-img("text_underline.svg");
		background-size: 100% 100%;
		background-position: center;
		z-index: -1;

		transform: scaleY(-1);

		@include breakpoint-lg {
			height: 6px;
		}
	}
}

.sparkle {
	position: absolute;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	transform: scale(0);
	z-index: -1;

	&--bottom-right {
		@include bg-img("sparkle-1.svg");

		transform-origin: 25% 15%;
		transform: scale(0);

		animation: sparkle 250ms ease-out 1s forwards;

		@include scaledToWidth(114, 112, 70);
		top: 95%;
		left: 90%;

		@include breakpoint-sm {
			left: 92%;
			@include scaledToWidth(114, 112, 80);
		}
		@include breakpoint-lg {
			left: 88%;
			@include scaledToWidth(114, 112, 90);
		}
		@include breakpoint-2xl {
			left: 90%;
			@include scaledToWidth(114, 112, 100);
		}
	}
	&--top-right {
		@include bg-img("sparkle-2.svg");

		transform-origin: 5% 75%;
		transform: scale(0);

		animation: sparkle 250ms ease-out 1s forwards;

		@include scaledToWidth(114, 112, 70);
		bottom: 95%;
		left: 95%;

		@include breakpoint-sm {
			// left: 92%;
			@include scaledToWidth(114, 112, 80);
		}
		@include breakpoint-lg {
			// left: 95%;
			@include scaledToWidth(114, 112, 90);
		}
		@include breakpoint-2xl {
			// left: 95%;
			@include scaledToWidth(114, 112, 100);
		}
	}
	&--top-left {
		@include bg-img("sparkle-2.svg");

		transform-origin: 5% 75%;
		transform: scale(0);

		animation: sparkle-invertedX 250ms ease-out 1s forwards;

		@include scaledToWidth(114, 112, 70);
		bottom: 95%;
		right: 80%;

		@include breakpoint-sm {
			// left: 92%;
			@include scaledToWidth(114, 112, 80);
		}
		@include breakpoint-lg {
			// left: 95%;
			@include scaledToWidth(114, 112, 90);
		}
		@include breakpoint-2xl {
			// left: 95%;
			@include scaledToWidth(114, 112, 100);
		}
	}
}

@keyframes sparkle {
	0% {
		transform: scale(0);
	}

	50% {
		transform: scale(1);
	}

	60% {
		transform: scale(1.4);
	}

	100% {
		transform: scale(1);
	}
}
@keyframes sparkle-invertedX {
	0% {
		transform: scaleX(0) scaleY(0);
	}

	50% {
		transform: scaleX(-1) scaleY(1);
	}

	60% {
		transform: scaleX(-1.4) scaleY(1.4);
	}

	100% {
		transform: scaleX(-1) scaleY(1);
	}
}
@keyframes sparkle-invertedY {
	0% {
		transform: scaleY(0) scaleX(0);
	}

	50% {
		transform: scaleY(-1) scaleX(1);
	}

	60% {
		transform: scaleY(-1.4) scaleX(1.4);
	}

	100% {
		transform: scaleY(-1) scaleX(1);
	}
}
@keyframes sparkle-invertedXY {
	0% {
		transform: scaleY(0) scaleX(0);
	}

	50% {
		transform: scaleY(-1) scaleX(-1);
	}

	60% {
		transform: scaleY(-1.4) scaleX(-1.4);
	}

	100% {
		transform: scaleY(-1) scaleX(-1);
	}
}

@keyframes sparkle-inner--top-right {
	0% {
		transform: scaleX(0) scaleY(0) rotate(-75deg);
	}

	50% {
		transform: scaleX(-1) scaleY(1) rotate(-75deg);
	}

	60% {
		transform: scaleX(-1.4) scaleY(1.4) rotate(-75deg);
	}

	100% {
		transform: scaleX(-1) scaleY(1) rotate(-75deg);
	}
}

// Integration carousel style tag
.tag {
	@include padding(x, 2);
	@include padding(y, 1);
	border-radius: 5px;

	display: inline-flex;
	justify-content: center;
	align-items: center;

	font-family: $font-family;
	font-weight: 700;
	font-size: 12px;
	text-align: center;

	&--teal {
		background-color: transparentize($access-teal, 0.8);
		color: $access-teal;
	}

	&--red {
		background-color: transparentize($access-red, 0.8);
		color: $access-red;
	}

	&--purple {
		background-color: transparentize($access-purple, 0.8);
		color: $access-purple;
	}

	&--grey {
		background-color: transparentize($grey-dark, 0.8);
		color: $grey-dark;
	}

	&--secondary {
		font-weight: 400;
	}
}

i.tick {
	display: inline-flex;
	width: 22px;
	height: 22px;

	@include bg-img("icons/tick.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}
