.contact-us {
	@include padding(t, 6);

	@include breakpoint-lg {
		@include padding(y, 8);
	}

	&__heading {
		.heading {
			@include margin(b, 3);
			font-size: 36px;
			font-weight: 700;
			color: $text-black;

			@include breakpoint-lg {
				font-size: 68px;
			}
		}

		p {
			@include margin(b, 6);
			font-size: 14px;
			color: $text-black;
			line-height: 1.6;

			@include breakpoint-lg {
				font-size: 18px;
			}
		}
	}

	// Phone / Email
	.contact-us__details {
		@include margin(b, 4);
		display: flex;
		flex-direction: column;

		@include breakpoint-lg {
			flex-direction: row;
			@include margin(b, 6);
		}

		a {
			@include margin(b, 4);
			display: flex;
			align-items: center;

			font-weight: bold;
			font-size: 16px;

			@include breakpoint-lg {
				@include margin(b, 0);
				@include margin(r, 4);
				font-size: 18px;
			}

			&::before {
				@include margin(r, 2);
				content: "";

				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
			}
		}
	}

	&__phone {
		&::before {
			@include bg-img("icons/phone-teal.svg");
			width: 21px;
			height: 22px;
		}
	}

	&__email {
		&::before {
			@include bg-img("icons/envelope-teal.svg");
			width: 22px;
			height: 15px;
		}
	}

	&__socials {
		@include margin(b, 7);
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		.social {
			@include margin(r, 5);

			height: 24px;

			@include breakpoint-lg {
				height: 20px;
			}
			img {
				height: 100%;
				width: auto;
			}
		}
	}

	&__image {
		@include margin(b, 7);
		width: 100%;

		img {
			width: 100%;
		}
	}

	.contact-us-form {
		background: $white;
		@include padding(y, 7);
		// @include padding(x, 5);

		// Fill to edge
		@include pin-both;

		// Add padding back in to match
		@include column-padding;

		@include breakpoint-lg {
			@include margin(x, 0);
			@include padding(x, 0);

			border-radius: $border-radius;
			box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
		}

		&__inner {
			max-width: 540px;
			margin-right: auto;
			margin-left: auto;

			@include breakpoint-lg {
				@include padding(x, 7);
				// max-width: none;
				margin-left: 0;
			}
		}
	}
}
