.accordion {
	@include padding(y, 7);

	.heading {
		@include margin(b, 3);
	}

	.subheading {
		@include breakpoint-lg {
			max-width: 80ch;
			margin: 0 auto;
		}
	}

	&__cards-wrapper {
		text-align: center;
		@include breakpoint-lg {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 60%;
			max-width: 680px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__cards {
		@include margin(t, 7);
		@include padding(x, 4);
		@include margin(x, -4);
		overflow: hidden;
		display: flex;
		flex-direction: column;
	}

	.accordion-card {
		@include margin(b, 5);

		display: flex;
		flex-direction: column;
		flex-shrink: 0;

		background: $white;
		border-radius: $border-radius;

		overflow: hidden;

		box-shadow: 6px 6px 12px rgba(211, 215, 220, 0.8);

		&__header {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			flex-grow: 1;
		}

		&__heading-text {
			@include padding(x, 4);
			@include padding(y, 4);

			flex-grow: 1;
			text-align: left;

			@include breakpoint-lg {
				@include padding(x, 6);
				@include padding(y, 6);

				display: flex;
				flex-wrap: wrap;
			}

			// Any siblings e.g. text or tags
			.title ~ * {
				@include margin(t, 1);

				@include breakpoint-lg {
					@include margin(r, 2);
				}
			}

			.title {
				@include breakpoint-lg {
					@include margin(r, 5);
				}
			}

			// Reducing line height to save some vertical space
			p {
				line-height: 1.4;

				@include breakpoint-lg {
					width: 100%;
				}
			}
		}

		&__heading-icon {
			position: relative;
			width: 72px;
			flex-shrink: 0;

			&::after {
				content: "";

				position: absolute;
				left: 50%;
				top: 50%;
				width: 34px;
				height: 34px;

				transform: translate(-50%, -50%);

				border: 1px solid $grey-dark;
				border-radius: 50%;

				@include bg-img("icons/plus-grey.svg");
				background-position: center;
				background-repeat: no-repeat;
				background-size: 10px auto;
			}

			&--arrow {
				&::after {
					@include bg-img("icons/arrow-black.svg");
					background-size: 14px auto;
				}
			}
		}
		&__content {
			display: none;
			@include padding(x, 4);
			@include padding(y, 4);

			@include breakpoint-lg {
				@include padding(x, 6);
				@include padding(y, 6);
			}
		}

		&.active {
			.accordion-card__header {
				color: white;
				// Default to teal
				background-color: $access-teal;

				// Change tag background to contrast assuming header has same colour e.g. teal tag on teal active header
				.tag {
					color: white;

					&--teal {
						background-color: $access-teal-lighter-20;
					}

					&--red {
						background-color: $access-red-lighter-20;
					}

					&--purple {
						background-color: $access-purple-lighter-20;
					}

					&--grey {
						background-color: $grey-light;
						color: $text-black;
					}
				}
			}

			// Active / Expanded header background colours
			&--teal .accordion-card__header {
				background-color: $access-teal;
			}
			&--red .accordion-card__header {
				background-color: $access-red;
			}
			&--purple .accordion-card__header {
				background-color: $access-purple;
			}
			&--grey .accordion-card__header {
				background-color: $grey-light;
			}

			.accordion-card__heading-icon {
				&::after {
					border-color: white;
					@include bg-img("icons/minus-white.svg");
				}
			}
			.accordion-card__content {
				display: block;
			}
		}
	}
}
