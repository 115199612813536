// Hoverable cta block
.link-block {
	@include padding(y, 5);
	@include padding(x, 5);

	display: flex;
	justify-content: space-between;
	align-items: center;

	font-weight: 700;
	font-size: 16px;

	color: $text-black;

	background-color: $bg-secondary;

	// border-top: 1px solid rgba(255, 255, 255, 0.2);

	transition: background-color 350ms ease-in-out, color 350ms ease-in-out;

	// Arrow button
	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background-color: transparent;
		border: 1px solid rgba(255, 255, 255, 0.4);

		transition: background-color 350ms ease-in-out, border-color 350ms ease-in-out;

		width: 34px;
		height: 34px;

		svg {
			width: 13px;

			path {
				stroke: rgba(255, 255, 255, 0.4);
				transition: stroke 350ms ease-in-out;
			}
		}
	}
}
