/* Margins START */
.m-0 {
	margin: 0 !important;
}

.my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.mx-0 {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.ml-auto {
	margin-left: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

@for $i from 1 through length($spacings) {
	.m-#{$i} {
		margin: #{nth($spacings, $i)} !important;
	}
	.-m-#{$i} {
		margin: -#{nth($spacings, $i)} !important;
	}

	// Horizontal
	.mx-#{$i} {
		margin-left: #{nth($spacings, $i)} !important;
		margin-right: #{nth($spacings, $i)} !important;
	}
	.-mx-#{$i} {
		margin-left: -#{nth($spacings, $i)} !important;
		margin-right: -#{nth($spacings, $i)} !important;
	}

	// Vertical
	.my-#{$i} {
		margin-top: #{nth($spacings, $i)} !important;
		margin-bottom: #{nth($spacings, $i)} !important;
	}
	.-my-#{$i} {
		margin-top: -#{nth($spacings, $i)} !important;
		margin-bottom: -#{nth($spacings, $i)} !important;
	}

	// Left
	.ml-#{$i} {
		margin-left: #{nth($spacings, $i)} !important;
	}
	.-ml-#{$i} {
		margin-left: -#{nth($spacings, $i)} !important;
	}

	// Right
	.mr-#{$i} {
		margin-right: #{nth($spacings, $i)} !important;
	}
	.-mr-#{$i} {
		margin-right: -#{nth($spacings, $i)} !important;
	}

	// Bottom
	.mb-#{$i} {
		margin-bottom: #{nth($spacings, $i)} !important;
	}
	.-mb-#{$i} {
		margin-bottom: -#{nth($spacings, $i)} !important;
	}

	// Top
	.mt-#{$i} {
		margin-top: #{nth($spacings, $i)} !important;
	}
	.-mt-#{$i} {
		margin-top: -#{nth($spacings, $i)} !important;
	}
}
/* Margins END */

/* Paddings START */
.p-0 {
	padding: 0 !important;
}

.py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.px-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

@for $i from 1 through length($spacings) {
	.p-#{$i} {
		padding: #{nth($spacings, $i)} !important;
	}

	// Horizontal
	.px-#{$i} {
		padding-left: #{nth($spacings, $i)} !important;
		padding-right: #{nth($spacings, $i)} !important;
	}

	// Vertical
	.py-#{$i} {
		padding-top: #{nth($spacings, $i)} !important;
		padding-bottom: #{nth($spacings, $i)} !important;
	}

	// Left
	.pl-#{$i} {
		padding-left: #{nth($spacings, $i)} !important;
	}

	// Right
	.pr-#{$i} {
		padding-right: #{nth($spacings, $i)} !important;
	}

	// Bottom
	.pb-#{$i} {
		padding-bottom: #{nth($spacings, $i)} !important;
	}

	// Top
	.pt-#{$i} {
		padding-top: #{nth($spacings, $i)} !important;
	}
}
/* Paddings END */
